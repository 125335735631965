export const isValidEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true
    }
    return false;
}

export const isValidPhone = (mail) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(mail)) {
        return true
    }
    return false;
}


export const tableEntriesOptions = [10, 20, 50, 100];