import React, { useState } from 'react'

export default function FaqSection() {  
    const [activeFaq, setActiveFaq] = useState(1);
    const faq = [
        {
            title: "What should I include in my personal statement?", 
            description:"At vero eos et accusamus et iusto odio digni goiku ssimos ducimus qui blanditiis praese. Ntium voluum deleniti atque corrupti quos."
        },
        {
            title: "How can I consult with the consultant team?", 
            description:"At vero eos et accusamus et iusto odio digni goiku ssimos ducimus qui blanditiis praese. Ntium voluum deleniti atque corrupti quos."
        },
        {
            title: "How can I make a change to my application?", 
            description:"At vero eos et accusamus et iusto odio digni goiku ssimos ducimus qui blanditiis praese. Ntium voluum deleniti atque corrupti quos."
        },
        {
            title: "What will happen when I’ve sent my application?", 
            description:"At vero eos et accusamus et iusto odio digni goiku ssimos ducimus qui blanditiis praese. Ntium voluum deleniti atque corrupti quos."
        }
    ]


    return (
        <section className="faq-section pb-0">
            <div className="pattern-layer"></div>
            <div className="auto-container">
                <div className="row clearfix">
                    {/* Accordian Column */}
                    <div className="accordian-column col--md-8 offset-md-2">
                        <div className="inner-column">
                            {/* Sec Title */}
                            <div className="sec-title style-two centered">
                                <div className="separater"></div>
                                <div className="title">Some Faquality Ask Question</div>
                                <h2>You Can Learn More From Our Asked Questions</h2>
                            </div>
                            <ul className={`accordion-box`}>
                            {
                                    faq.map((item, key) =>
                                        <li key={key} className={`accordion block ${activeFaq === key ? 'active-block' : ''}`} onClick={() => setActiveFaq(key)}>
                                            <div className={`acc-btn ${activeFaq === key ? 'active' : ''}`}>{item.title} <div className="icon fa fa-angle-down"></div>
                                            </div>
                                            <div className={`acc-content ${activeFaq === key ? 'current' : 'd-none'}`}>
                                                <div className="content">
                                                    <div className="text">{item.description}</div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>                   
                </div>
            </div>
        </section>
    )
}
