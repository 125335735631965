import { createSlice } from '@reduxjs/toolkit'
import { authCheck, getCurrentUser } from '../services/auth';
export const counterSlice = createSlice({
    name: 'auth',
    initialState: {
        auth_user: authCheck() ? getCurrentUser() : null,
        access_token: authCheck() ? getCurrentUser().access_token : null,
    },
    reducers: {
        authUser: (state, action) => {
            state.auth_user = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { authUser } = counterSlice.actions

export default counterSlice.reducer