const getBaseUrl = () => process.env.REACT_APP_API_URL;

export const getUrl = (type) => {
  const baseUrl = getBaseUrl() + '/api';
  switch (type) {
    case 'register':
      return `${baseUrl}/v1/auth/register`;
    case 'user':
      return `${baseUrl}/v1/auth/user`;
    case 'profile':
      return `${baseUrl}/v1/auth/profile`;
    case 'login':
      return `${baseUrl}/v1/auth/login`;
    case 'google-login':
      return `${baseUrl}/v1/auth/login/google`;
    case 'contact-us':
      return `${baseUrl}/contact-us`;
      case 'newslatter-subscription':
        return `${baseUrl}/newslatter-subscription`;
    case 'forgot-password':
      return `${baseUrl}/v1/auth/password/forgot`;
    case 'check-forgot-password-token':
      return `${baseUrl}/v1/auth/password/reset/verify`;
    case 'check-set-password-token':
      return `${baseUrl}/v1/auth/password/set/verify`;
    case 'reset-password':
      return `${baseUrl}/v1/auth/password/reset`;
    case 'set-password':
      return `${baseUrl}/v1/auth/password/set`;
    case 'change-password':
      return `${baseUrl}/v1/auth/password/change`;
    case 'email-verification-link':
      return `${baseUrl}/v1/send-email-verification-link`;
    case 'logout':
      return `${baseUrl}/v1/auth/logout`;
    default:
      return baseUrl;
  }
};