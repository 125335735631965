import React from 'react'
import { Link } from 'react-router-dom'

export default function GalleryAreaSection() {
    return (
        <section className="about-section">
            <div className="pattern-layer"></div>
            <div className="auto-container">
                <div className="row clearfix">
                    {/* Image Column */}
                    <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{ 'visibility': 'visible', 'animationDuration': '1500ms', 'animationDelay': '0ms', 'animationName': 'fadeInLeft' }}>
                            <div className="color-layer"></div>
                            <div className="title">WHY ULMECH</div>
                            <div className="image">
                                <div className="color-layer-two"></div>
                                <div className="color-layer-three"></div>
                                <img loading="lazy" src="/images/resource/about.webp" width={710} height={870} alt="" />
                            </div>
                        </div>
                    </div>
                    {/* Content Column */}
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title style-three">
                                <div className="separater"></div>
                                <div className="title">Why ULMECH</div>
                                <h2>Prioritizing Our Clients</h2>
                            </div>
                            <div className="text">
                                <p> At ULMECH, we pride ourselves on prioritizing our clients above all else. With a customer-centric approach, we go above and beyond to understand your unique needs and preferences. Our dedicated team is committed to delivering personalized solutions that exceed your expectations. From project inception to completion, your satisfaction is our top priority. Trust ULMECH to be your partner in construction, where your vision becomes our mission. Contact us today to experience the difference of working with a team that truly puts you first.</p>
                            </div>
                            <div className="clearfix">
                                <div className="pull-left">
                                    <div className="phone">Call To Ask Any Question <strong><Link to="tel:07889005345">07889005345</Link></strong></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
