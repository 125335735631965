import { createSlice } from '@reduxjs/toolkit'
export const counterSlice = createSlice({
  name: 'app',
  initialState: {
    loader: 0,
    sidebar_collapse: window.innerWidth > 991 ? false : true,
    sidebar_tab_collapse: [],
  },
  reducers: {
    loader: (state, action) => {
      if (action.payload) {
        state.loader++;
      } else {
        state.loader--;
      }
    },
    loaderStop: (state) => {
      state.loader = 0;  // stop loader 
    },
    sidebarCollapse: (state) => {
      state.sidebar_collapse = !state.sidebar_collapse;
    },
    sidebarTabCollapse: (state, action) => {
      if (state.sidebar_tab_collapse.includes(action.payload)) {
        state.sidebar_tab_collapse = state.sidebar_tab_collapse.filter(i => i !== action.payload); // Remove from collapse list
      } else {
        state.sidebar_tab_collapse = [...state.sidebar_tab_collapse, action.payload]; // Add to collapse list
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { loader, loaderStop, sidebarCollapse, sidebarTabCollapse } = counterSlice.actions

export default counterSlice.reducer