import React from 'react'
import { Link } from 'react-router-dom'

export default function LiquidAppliedMembraneServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(https://sika.scene7.com/is/image/sika/glo-liquid-applied-membrane-roof-03:16-9?wid=2440&hei=1373&fit=crop%2C1&fmt=webp-alpha)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
        <div className="auto-container">
          <h1>LIQUID-APPLIED MEMBRANE</h1>
          <ul className="page-breadcrumb d-none">
            <li><Link to="/">home</Link></li>
            <li><Link to="/roofing">Roofing</Link></li>
            <li>Liquid Applied Membrane</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li><Link to="/roofing">Roofing</Link></li>
                <li>Liquid Applied Membrane</li>
              </ul>
              <div className="service-detail">
                <div className="inner-box">
                  <p className="head-sub-para">Liquid-applied membrane (LAM) is a monolithic, fully-bonded, liquid-based coating suitable for many waterproofing and roofing applications. The coating cures to form a rubber-like elastomeric waterproof membrane and may be applied over many substrates, including asphalt, bitumen and concrete.</p>
                  <p className="head-para text-center mt-5 mb-4">Liquid-Applied Membrane Is a Popular Choice Because It Is...</p>
                  <div className="lower-content">
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>Ideal for exposed roofs with large number of complicated details and penetrations</li>
                          <li>Cost-effective, especially for roof renovations and roof repair projects</li>
                          <li>Seamless, fills in cracks, gaps and voids in substrate</li>
                        </ul>
                      </div>
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>Easy and safe application</li>
                          <li>Durable</li>
                          <li>Rapid curing and excellent adhesion to all kinds of surfaces</li>
                          <li>Applicable for a multitude of waterproofing needs, not only roofs</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* Table*/}
                  <p className="head-para text-center mt-5 mb-4">Benefits of Liquid-Applied Membranes</p>


                  <div className="table-main-div">
                    <div className="container-fluid p-0 table-responsive">
                      <table className="table-area">

                        <thead>
                          <th>Benefits</th>
                          <th>Features</th>
                        </thead>

                        <tbody>

                          <tr>
                            <td>
                              Fast, easy and safe application
                            </td>
                            <td>
                              <div className="pay-list-text-div">
                                Liquid-applied
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Extend life cycle of old or leaking roofs without interruption <br />
                              Suitable for various types of structures and substrates <br />
                              Many possible applications, for roofs, roads, bridges, basements, terraces and more
                            </td>
                            <td>
                              <div className="pay-list-text-div">
                                Versatile
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Lower risk of roof failure even in cold climate and longer life expectancy
                            </td>
                            <td>
                              <div className="pay-list-text-div">
                                Highly elastic <br />
                                Seamless <br />
                                Fully adhered to substrate <br />
                                High UV resistance <br />
                                Retains flexibility even at low temperatures
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Safety <br />
                              Reduced fire risk
                            </td>
                            <td>
                              <div className="pay-list-text-div">
                                Zero flame technology
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Entrapped moisture can evaporate, no blistering
                            </td>
                            <td>
                              <div className="pay-list-text-div">
                                Vapor permeable
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Design freedom with more possible applications
                            </td>
                            <td>
                              <div className="pay-list-text-div">
                                Variety of color options
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Improved energy efficiency for cool roofs and solar roofs
                            </td>
                            <td>
                              <div className="pay-list-text-div">
                                High solar reflectance index when applied in white color
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Application example */}
                  <p className="head-para text-center mt-5 mb-4">Typical Application Fields for Liquid-Applied Membranes</p>
                  <div className="row clearfix mt-4">
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-liquid-applied-membrane-roof-congress-hall-01?fit=crop%2C1&wid=480&hei=341&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Roofing</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-courtyard-glass-facade?fit=crop%2C1&wid=480&hei=363&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Terraces</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-liquid-spray-applied-membrane-sikalastic?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Below-Grade Structures</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-waterproofing-mortar-bathroom-01?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Wet Rooms and Bathrooms</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-liquid-spray-applied-membrane-wall?fit=crop%2C1&wid=480&hei=373&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Basements</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-liquid-applied-membrane-floor-02?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Bridge Decks</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
