import React from 'react'
import { Link } from 'react-router-dom'

export default function WallSawingServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title services-hero">
        <div className="auto-container">
          <h1>WALL SAWING</h1>
          <p>Precise, clean, dust-free cuts for custom work and more.</p>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li><Link to="/sawing">Sawing</Link></li>
                <li>Wall Sawing</li>
              </ul>
              <div className='row clearfix'>
                <div className='col-md-6'>
                  <div className="sigle-service-details">
                    {/* About the service */}
                    <h2>WALL SAWING OR VERTICAL CONCRETE CUTTING DETAILS</h2>
                    <p>Wall sawing is the cleanest, safest, most effective way to create door and window openings in concrete. Despite the name, concrete wall cutting is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and floors.</p>
                    <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>

                    {/* Job of any size area */}
                    <h3>Jobs of Any Size</h3>
                    <p>For a small touchup, elimination of overcutting or a large-scale hand-sawing job, our team is ready. Our trucks are stocked with any saw we may need for your project.</p>
                  </div>
                </div>
                <div className='col-md-6 mt-4 mt-md-0'>
                  <div className='container-fluid'>
                    <div className='row clearfix service-list-holder'>
                      {/* Block 1 */}
                      <div className='col-6 col-md-12 col-lg-6 mb-4'>
                        <p className='que'>DEPTH- CHAIN SAW</p>
                        <p className='ans'>up to 36” deep</p>
                      </div>
                      {/* Block 2 */}
                      <div className='col-6 col-md-12 col-lg-6  mb-4'>
                        <p className='que'>DEPTH- HAND SAW</p>
                        <p className='ans'>up to 16" deep</p>
                      </div>
                      {/* Block 3 */}
                      <div className='col-6 col-md-12 col-lg-6  mb-4'>
                        <p className='que'>TYPE OF CUT</p>
                        <p className='ans'>Straight, Adjacent flush cut</p>
                      </div>
                      {/* Block 4 */}
                      <div className='col-6 col-md-12 col-lg-6  mb-4'>
                        <p className='que'>MATERIALS CUT</p>
                        <p className='ans'>Concrete, Rebar, Block</p>
                      </div>
                      {/* Block 5 */}
                      <div className='col-6 col-md-12 col-lg-6  mb-4'>
                        <p className='que'>POWERED BY</p>
                        <p className='ans'>Electric, Hydraulic</p>
                      </div>
                      {/* Block 6 */}
                      <div className='col-6 col-md-12 col-lg-6  mb-4'>
                        <p className='que'>DUST FREE</p>
                        <p className='ans'>Yes</p>
                      </div>
                      {/* Block 7 */}
                      <div className='col-6 col-md-12 col-lg-6  mb-4'>
                        <p className='que'>CUT FLUSH TO ADJACENT SURFACE?</p>
                        <p className='ans'>Yes</p>
                      </div>
                      {/* Block 8 */}
                      <div className='col-6 col-md-12 col-lg-6  mb-4'>
                        <p className='que'>BEST FOR:</p>
                        <p className='ans'>Custom work</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="serviceCarousel" className="carousel slide services-carousel" data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#serviceCarousel" data-slide-to="0" className="active"></li>
                  <li data-target="#serviceCarousel" data-slide-to="1"></li>
                  <li data-target="#serviceCarousel" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="https://fmgconcretecutting.com/wp-content/uploads/2021/02/Chainsaw_web-1440x870.jpg" className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="https://fmgconcretecutting.com/wp-content/uploads/2021/02/GIFFIN_web-1440x833.jpg" className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="https://fmgconcretecutting.com/wp-content/uploads/2021/02/Wall-Saw_Ring-Sawing_web.jpg" className="d-block w-100" alt="..." />
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-target="#serviceCarousel" data-slide="prev">
                </button>
                <button className="carousel-control-next" type="button" data-target="#serviceCarousel" data-slide="next">
                </button>
              </div>

              <div className="row clearfix">
                <div className="col-md-6 col-lg-5 sigle-service-details">
                  <h2>INTERESTED IN OUR SERVICES?</h2>
                  <p>We guarantee prompt, friendly service. For information on pricing or any type of concrete cutting, please ask for an estimate. We’ll give you a quote over the phone or in writing depending on the scope of the job. Call 810.227.3650 or click the button below.</p>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="row clearfix">
                    <div className='col-lg-6 mb-3 mb-lg-0'>
                      <div className='service-interest-boxes'>
                        <p>NEED TO MAKE A DEEPER CUT?</p>
                        <a href=''>WIRE SAWING</a>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='service-interest-boxes'>
                        <p>NEED TO MAKE A ROUND CUT?</p>
                        <a href=''>CORE DRILLING</a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

             
              {/* Compare table */}
             <div className='compare-services-table'>
             <div className="sigle-service-details centered mb-5">
                  <h2>COMPARE SERVICES</h2>
                </div>
              <div className='compare-table-div'>
              
                  <table>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Suggested For</th>
                        <th>Depth of Cut</th>
                        <th>Materials Used On</th>
                        <th>Powered By</th>
                        <th>Cut Type</th>
                        <th>Dust Free</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><a href="">Wall Sawing</a></td>
                        <td>Walls, Ceilings, Floors</td>
                        <td>Up to 36”</td>
                        <td>Concrete, Block</td>
                        <td>Electric, Hydraulic</td>
                        <td>Straight, Adjacent, Flush cut</td>
                        <td><img src="/images/icons/DustFreeIcon.svg" alt="Dust Icon" /></td>
                      </tr>
                      <tr>
                        <td><a href="">Curb Cutting</a></td>
                        <td>Driveways, Sidewalks, Wheelchair ramps, Subdivision entrances</td>
                        <td>24” thick</td>
                        <td>Concrete, Asphalt</td>
                        <td>Diesel</td>
                        <td>Straight or Curved cut</td>
                        <td><img src="/images/icons/DustFreeIcon.svg" alt="Dust Icon" /></td>
                      </tr>
                      <tr>
                        <td><a href="">Core Drilling</a></td>
                        <td>Round openings, Sewers, Plumbing, HVAC</td>
                        <td>Any depth, ½”-84” in diameter</td>
                        <td>Concrete, Steel</td>
                        <td>Electric, Hydraulic</td>
                        <td>Round cut</td>
                        <td><img src="/images/icons/DustFreeIcon.svg" alt="Dust Icon" /></td>
                      </tr>
                      <tr>
                        <td><a href="">Slab Sawing</a></td>
                        <td>Large Areas, Roads, Warehouses</td>
                        <td>30” – 48”</td>
                        <td>Concrete, Steel</td>
                        <td>Electric, Battery, Diesel</td>
                        <td>Straight cut</td>
                        <td><img src="/images/icons/DustFreeIcon.svg" alt="Dust Icon" /></td>
                      </tr>
                      <tr>
                        <td><a href="">Wire Sawing</a></td>
                        <td>Very thick concrete</td>
                        <td>Any depth, 30”-48”, up to 30’ in diameter</td>
                        <td>Concrete, Steel</td>
                        <td>Electric, Diesel</td>
                        <td>Straight cut</td>
                        <td><img src="/images/icons/DustFreeIcon.svg" alt="Dust Icon" /></td>
                      </tr>
                      <tr>
                        <td><a href="">Hand Sawing</a></td>
                        <td>Custom work</td>
                        <td>30”-48”</td>
                        <td>Concrete, Steel, Block</td>
                        <td>Electric, Hydraulic</td>
                        <td>Straight, Adjacent Flush cut</td>
                        <td><img src="/images/icons/DustFreeIcon.svg" alt="Dust Icon" /></td>
                      </tr>
                      <tr>
                        <td><a href="">Grinding</a></td>
                        <td>Concrete leveling, Surface prep for concrete floors prior to the installation of ceramic tile, wood floors, or carpet</td>
                        <td>N/A</td>
                        <td>Concrete</td>
                        <td>Electric</td>
                        <td>Surface Prep</td>
                        <td><img src="/images/icons/DustFreeIcon.svg" alt="Dust Icon" /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
