import React from 'react'
import { Link } from 'react-router-dom'

export default function ConcreteFlooringServicePage() {
  return (
    <div className="page-wrapper">
    {/* Page Title */}
    <section className="page-title" style={{ backgroundImage: 'url(https://sika.scene7.com/is/image/sika/glo-concrete-floor-warehouse-01:16-9?wid=2440&hei=1373&fit=crop%2C1&fmt=webp-alpha)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover'}}>
      <div className="auto-container">
        <h1>CONCRETE FLOORING</h1>
        <ul className="page-breadcrumb d-none">
          <li><Link to="/">home</Link></li>
          <li><Link to="/flooring">Flooring</Link></li>
          <li>Concrete Flooring</li>
        </ul>
      </div>
    </section>
    {/* End Page Title */}
    {/* Sidebar Page Container */}
    <div className="sidebar-page-container left-sidebar">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-md-10 offset-md-1">
            <ul className="page-breadcrumb">
              <li><Link to="/">home</Link></li>
              <li><Link to="/flooring">Flooring</Link></li>
              <li>Concrete Flooring</li>
            </ul>
            <div className="service-detail">
              <div className="inner-box">
                <p className="head-sub-para">Concrete floors are used as the base slab for most commercial and industrial floor finishes. They are characterized by high quality, durability and versatility relative to cost. Since concrete flooring is able to withstand pressure from heavy equipment, such as trucks or forklifts, it’s frequently used in warehouses, factories and production facilities.</p>
                <p className="head-para text-center mt-5 mb-4">Concrete Floors Meet the Highest Flooring Requirements</p>
                <div className="lower-content">
                  <div className="row clearfix">
                    <div className="column col-md-6 offset-md-3">
                      <ul className="list-style-three">
                        <li>Support operational and stationary loads without cracking or deforming</li>
                        <li>Minimize the number of exposed joints</li>
                        <li>Utilize maintenance isolation joints that do not impede vehicle operating speed</li>
                        <li>Provide a durable abrasion resistant and dust-free surface</li>
                        <li>Appropriate levelness and flatness tolerances to support material handling systems (“MHE”)</li>
                        <li>Balance surface texture traction with cleanability</li>
                        <li>Provide a safe and pleasant working environment</li>
                        <li>Are flexible to accommodate possible future changes in operations</li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* List */}
                <p className="head-para text-center mt-5 mb-4">Concrete Flooring Solutions Benefit Your Project</p>
                <div className="lower-content mb-5">
                  <div className="row clearfix">
                    <div className="column col-lg-6 col-md-12 col-sm-12">
                      <p className="list-style-heading">For new buildings:</p>
                      <ul className="list-style-three">
                        <li>To produce concrete slabs which will form a sound foundation, use our concrete admixtures technology.</li>
                        <li>Use Sikafloor® “dry shake” solutions which, after hardening monolithically with the base concrete, create an integrated and extremely hard-wearing floor.</li>
                        <li>Liquid-applied curing agents, densifiers and sealers enhance the concrete surface to a higher quality level and provide durability in use.</li>
                        <li>If time is a key factor in your project, apply Sika® EpoCem® technology. It is used on relatively new “green” or existing damp concrete, where it acts as a temporary moisture barrier to reduce waiting times for the application of vapor-tight floor systems.</li>
                        <li>Thanks to our Sikafloor® solutions, you can choose a bright colored floor that can be installed in a wide range of thicknesses and with a variety of surface textures.</li>
                      </ul>
                    </div>
                    <div className="column col-lg-6 col-md-12 col-sm-12">
                      <p className="list-style-heading">For floor repair:</p>
                      <ul className="list-style-three">
                        <li>To provide a uniform and level surface for the application of floor finishes, use cementitious Sikafloor® screeds and Sikafloor® Level.</li>
                        <li>The perfectly matching moisture mitigation system for every application provides safety and enables fast installation on substrates with high moisture content. </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
