import React from 'react'
import TestimonialAreaComponent from '../components/home/TestimonialAreaComponent'
import CounterAreaSection from '../components/home/CounterAreaSection'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom'

const owlConfig = {
  lazyLoad: true,
  lazyLoadEager: 1,
  autoplayHoverPause: true,
  animateOut: 'fadeOut',
  animateIn: 'fadeIn',
  loop: true,
  margin: 45,
  dots: false,
  nav: true,
  smartSpeed: 500,
  autoplay: 6000,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    800: {
      items: 3,
    },
    1024: {
      items: 3,
    },
    1200: {
      items: 3,
    }
  }
};

export default function AboutUsPage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(/images/background/2.jpg)' }}>
        <div className="auto-container">
          <h1>About Us</h1>
          <ul className="page-breadcrumb bg-transparent border-0 position-relative d-none">
            <li><Link to="/" className="text-white">home</Link></li>
            <li>About</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* What we do and who we are start */}
      <section className="about-section pt-5 mt-5 pb-0">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-column col-lg-5">
              <div className="elementor-widget-container">
                <img width="631" height="605" src="/images/gallery/23.jpg" />
              </div>
            </div>
            <div className="content-column col-lg-7">
              <div className="inner-column">
                <div className="sec-title style-three mb-3">
                  <div className="separater"></div>
                  <div className="title">About ULMECH</div>
                  <h2>Who we are & what we do</h2>
                </div>
                <div className="text">
                  <p>ULMECH Construction, founded in 2024 and headquartered in Delhi, originated from Evergreen Enterprises established in 2013 in Erode, India, specializing in Borewell Mining and Drilling Contracting. ULMECH has grown into a comprehensive construction and infrastructure service provider, handling Hydro Power Projects, Dams, Tunnels, Solar Power Plants, Commercial Buildings, and various civil engineering works both domestically and internationally. The company also supplies construction equipment globally.</p>
                  <p>ULMECH offers drilling and grouting services such as TAM Grouting, Consolidation Grouting, Tunnel Contact Grouting, and Slope Stabilization, as well as Rock Anchors, Rock Bolting, Shotcreting, Rockfall Barriers, Micropiling, Ground Anchors, and Prestressing. Equipped with advanced machinery, including various Drilling Rigs, ULMECH ensures efficient project execution.</p>
                  <p>Committed to delivering high-quality work and continuous growth, ULMECH aims to increase its annual turnover year-on-year, supported by a team of seasoned professionals dedicated to meeting client needs. For more details, refer to our enclosed company profile.</p>
                </div>
                <div className="clearfix">
                  <div className="pull-left">
                    <div className="phone">Call To Ask Any Question <strong><Link to="tel:07889005345">07889005345</Link></strong></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pictures of owner */}
      <section className="testimonial-section-two pb-0">
        <div className="auto-container">
          <div class="sec-title style-two centered"><div class="separater"></div><div class="title">Owners</div><h2>We Provide Our Service With
          Our Expert Team </h2></div>

          <OwlCarousel className="four-item-carousel owl-carousel owl-theme owl-loaded owl-drag" {...owlConfig}>
            <div className="owl-stage-outer">
              <div className="owl-stage">

                <div className="owl-item">
                  <div className="team-block">
                    <div className="inner-box">
                      <div className="image">
                        <img loading="lazy" decoding="async" width="370" height="507" src="https://media.istockphoto.com/id/1354898581/photo/shot-of-a-young-businessman-using-a-laptop-in-a-modern-office.jpg?s=612x612&w=0&k=20&c=dDDNcvIoG-4VdO01ZlENqODBoNocT434vIFp0duuTZM=" alt="Owner" />

                        <div className="circular-menu">
                          <div className="items-wrapper">
                            <a className="menu-item" href="https://www.facebook.com/"><span className="fa fa-facebook"></span></a>
                            <a className="menu-item" href="https://www.linkedin.com/"><span className="fa fa-linkedin"></span></a>
                            <a className="menu-item" href="https://www.skype.com/"><span className="fa fa-skype"></span></a>
                            <a className="menu-item" href="https://www.twitter.com/"><span className="fa fa-twitter"></span></a>
                          </div>
                          <button className="floating-btn"><i className="flaticon-plus"></i></button>
                        </div>
                      </div>
                      <div className="lower-box">
                        <h3>Yousuf</h3>
                        <div className="designation">Owner</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="team-block">
                    <div className="inner-box">
                      <div className="image">
                        <img loading="lazy" decoding="async" width="370" height="507" src="https://media.istockphoto.com/id/1354898581/photo/shot-of-a-young-businessman-using-a-laptop-in-a-modern-office.jpg?s=612x612&w=0&k=20&c=dDDNcvIoG-4VdO01ZlENqODBoNocT434vIFp0duuTZM=" alt="Owner" />

                        <div className="circular-menu">
                          <div className="items-wrapper">
                            <a className="menu-item" href="https://www.facebook.com/"><span className="fa fa-facebook"></span></a>
                            <a className="menu-item" href="https://www.linkedin.com/"><span className="fa fa-linkedin"></span></a>
                            <a className="menu-item" href="https://www.skype.com/"><span className="fa fa-skype"></span></a>
                            <a className="menu-item" href="https://www.twitter.com/"><span className="fa fa-twitter"></span></a>
                          </div>
                          <button className="floating-btn"><i className="flaticon-plus"></i></button>
                        </div>
                      </div>
                      <div className="lower-box">
                        <h3>Suhel Salmani</h3>
                        <div className="designation">Owner</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="team-block">
                    <div className="inner-box">
                      <div className="image">
                        <img loading="lazy" decoding="async" width="370" height="507" src="https://media.istockphoto.com/id/1354898581/photo/shot-of-a-young-businessman-using-a-laptop-in-a-modern-office.jpg?s=612x612&w=0&k=20&c=dDDNcvIoG-4VdO01ZlENqODBoNocT434vIFp0duuTZM=" alt="Owner" />

                        <div className="circular-menu">
                          <div className="items-wrapper">
                            <a className="menu-item" href="https://www.facebook.com/"><span className="fa fa-facebook"></span></a>
                            <a className="menu-item" href="https://www.linkedin.com/"><span className="fa fa-linkedin"></span></a>
                            <a className="menu-item" href="https://www.skype.com/"><span className="fa fa-skype"></span></a>
                            <a className="menu-item" href="https://www.twitter.com/"><span className="fa fa-twitter"></span></a>
                          </div>
                          <button className="floating-btn"><i className="flaticon-plus"></i></button>
                        </div>
                      </div>
                      <div className="lower-box">
                        <h3>Hamza</h3>
                        <div className="designation">Owner</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </OwlCarousel>

        </div>
      </section>


      {/* What we do and who we are end */}
      {/* Testimonial Section */}
      <TestimonialAreaComponent />
      <CounterAreaSection />
    </div>

  )
}
