import React from 'react'
import { Link } from 'react-router-dom'

export default function ExperienceAndExpertisePage() {
  return (
    <>
      {/* End Main Header */}
      <div className="page-wrapper">
        {/* Page Title */}
        <section className="page-title" style={{ backgroundImage: 'url(/images/background/8.jpg)' }}>
          <div className="auto-container">
            <h1>Our Experience and Expertise</h1>
            <ul className="page-breadcrumb">
              <li><Link to="/">home</Link></li>
              <li>Our Experience</li>
            </ul>
          </div>
        </section>
        {/* End Page Title */}
        {/* Services Page Section */}
        <section className="services-page-section mb-5">
          <div className="auto-container">
            <div className="row clearfix">
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-4.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon flaticon-buildings"></div>
                    <h4><Link to="">Slope Protection</Link></h4>
                    <div className="text">Thesafetyofexcavations, slopesand adjacent structures is assured with a optimally designed and constructed earth retention and shoring system.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-5.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon flaticon-buildings"></div>
                    <h4><Link to="">Shotcreting / Concrete Spray</Link></h4>
                    <div className="text">It is a method by which concrete is pneumatically conveyed on to a surface during construction. The concrete is strengthened with steel mesh or rods. It is a method by which concrete is pneumatically conveyed on to a surface during construction. The concrete is strengthened withsteelmeshor rods.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-6.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Rock Bolting</Link></h4>
                    <div className="text">Rock bolting is a technique used in tunneling and mining where a steel rod is inserted into into the rocks. It is to impart support totheroofandsidesof thetunnel.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-9.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon flaticon-buildings"></div>
                    <h4><Link to="">Ground Anchor</Link></h4>
                    <div className="text">It isadevicethat isde-signedtosupport structures and is used in geo technical and constructionapplications. Itcanbereferred to as a percussion driven Soil anchor, Rock boltand Cableanchor.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon flaticon-buildings"></div>
                    <h4><Link to="">Self Drilling Anchor</Link></h4>
                    <div className="text">Self drilling anchors are a particular kind of rod anchors. They combine the processes of drilling. insertion as well as grouting. This greatly reduces the time taken to complete theproject.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">High Tensile Mitigation</Link></h4>
                    <div className="text">Steel Structures with high strength and tensility are used to prevent rock fall. They is highly durable and can with stand heavy loads. They can be used as a drapery or along withplates toanchor themesh.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Rock Fall Barriers</Link></h4>
                    <div className="text">They contain a specific braking system. The energy absorption is structured in such a way that the performance is consistent even over time. The barrier is resistant to damagebycorrosion.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Drilling</Link></h4>
                    <div className="text">Rotary & Odex drilling utilizes a rotating cutting head on a long shaft to create deep observation boreholes for rock sample collection. During drilling, air, water, or drilling mud is circulated to cool the cutting headandlubricatethesystem.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Tam Grouting</Link></h4>
                    <div className="text">Tube a Manchette (TAM) grouting is a method where in perforated pipes are used in combination with a special grout. These pipes/tubesarethenthrust intotheholefor grouting. The small portions of rubber sleeve (Manchette) are present on the outer portion of the pipe that acts as a one way valve. It is commonly used in tunnel excavations.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Consolidation Grouting</Link></h4>
                    <div className="text">This technique is used when open voids that are present in rock mass with fluid matter. This reduces permeability by improving the shear strength, Also, this reduces deformability. It also helps to reduce the outward flow of water in high pressure water tunnels.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Curtain Grouting</Link></h4>
                    <div className="text">Curtain grouting is the technique where a curtain or barrier of grout is constructed to reduce permeability. It is done by drilling and grouting a series of holes.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Tunnel Contact Grouting</Link></h4>
                    <div className="text">During the Tunnel Contact grouting process, grout is injected into voids between a tunnel liner and rock surrounding it. Additional consolidation of rock formation surrounding the tunnel is also achieved with this technique.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Aqueduct Expansion Joints</Link></h4>
                    <div className="text">AQUEDUCT EXPANSION JOINT It is a system of pipes, ditches, canals, tunnels, and supporting structures used to convey water from its source to its main distribution point. Such systems generally are used to supply citiesandagricultural lands with water.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Crash Barrier Diamond Cutting</Link></h4>
                    <div className="text">Dismantling of existing bridge expansion joint concrete reinforcement carefully & fixing new additional reinforcement with thesupportofShuttering.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Bridge Expansion Joint</Link></h4>
                    <div className="text">An expansion joint or movement joint is an assembly designed to safely absorb the temperature-included expansion and contraction of construction materials, to absorb vibration, to hold parts together, or to allow movement due to ground settlementorearthquakes.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Pavement Expansion Joint</Link></h4>
                    <div className="text">An expansion joint or movement joint is an assembly designed to safely absorb the temperature-included expansion and contraction of construction materials, to absorb vibration, to hold parts together, or to allow movement due to ground settlement or earth quakes.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">App Membrane</Link></h4>
                    <div className="text">It is a method to reinforce or stabilize common shallow slips of residual soil cut slopes or enhance slope stability when cut slopes are very high or steep with low or unsatisfactory factorof safety.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Bituminous Waterproofing</Link></h4>
                    <div className="text">Used for decades as waterproof coverings in residential and commercial roofs, these bitumen compositional membranes incorporate two layers. The first underside polymer membrane is used as a solid background, often reinforced with glass fibers. Mineral granules make up the self- protective top layer, with a final bituminous mixtureencapsulatingthemboth.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Acrylic Based Polymer Waterproofing</Link></h4>
                    <div className="text">Acrylic elastomer is a general term for a type of synthetic rubber whose primary component is acrylic acid alkyl ester (ethyl or butyl ester). Acrylic elastomer possesses characteristics of heat and oil resistance, with the ability to withstand temperatures of 170- 180°C. It is used primarily for producing oil sealsandpackagingrelatedtoautomobiles.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Cementitious Waterproofing</Link></h4>
                    <div className="text">The application method of cementitious waterproof coating typically, cementitious systems are applied in two coats after the initial preparatory work is complete. First coats may be proprietary materials only. Second coats are usually the chemical or metallic materials within a cement-and-sand mixture.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Crystalline Waterproofing</Link></h4>
                    <div className="text">Crystalline waterproofingisaprocess where theactiveingredients inacrystallineproduct react withthefreelimeandmoistureinthe concretetoformcrystals withinthecapillary systemof theconcrete. Inother words, the crystallinetechnology is self-sealingbecause theconcreteitselfbecomesa waterbarrier.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Soil Nailing And Guniting</Link></h4>
                    <div className="text">It is amethodtoreinforceor stabilize common shallow slips of residual soil cut slopes or enhance slope stability when cut slopes are very high or steep with low or unsatisfactory factorof safely.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Acrylic Based Polymer Waterproofing</Link></h4>
                    <div className="text">Acrylic elastomer is a general term for a type of synthetic rubber whose primary component is acrylic acid alkyl ester (ethyl or butyl ester). Acrylic elastomer possesses characteristics of heat and oil resistance, with the ability to withstand temperatures of 170-180°C. It is used primarily for producing oil seals and packaging related to automobiles.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Pile Foundation</Link></h4>
                    <div className="text">A pile foundation is defined as a series of columns constructed or inserted into the ground to transmit loads to a lower level of subsoil. A pile is a long cylinder made up of a strong material, such as concrete. ... Piles transfer the loads from structures to hard strata, rocks, or soil with high bearing capacity.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Micro Piling</Link></h4>
                    <div className="text">Micro piles are high-performance, high- capacity drilled deep foundation elements typically between 5-12 inches in diameter that can extend to depths of 200 feet and achieve working loads of over 200 tons. Micro piles are comprised of high-strength steelcasing, rebarandgrout.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Epoxy Coating</Link></h4>
                    <div className="text">An epoxy coating is a coating compound consisting of two distinct elements: an epoxy resin and a polyamine hardener (also known as a catalyst). When mixed, the resin and hardener engage in a chemical reaction thatcreatescross-linkingof theelementsas itcures.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Thermoplastic Polyolefin (TPO)</Link></h4>
                    <div className="text">Single-ply roofing membranes are among the fastest growing commercial roofing products and have gained praise across the industry for their many performance and installationadvantages.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Canal Lining</Link></h4>
                    <div className="text">Canal lining is the process of reducing seepage loss of irrigation water by adding an impermeable layer to the edges of the trench. Seepage can result in losses of 30 to 50 percent of irrigation water from canals, so adding lining can make irrigation systems moreefficient.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Gallery Dam Rehabilitation</Link></h4>
                    <div className="text">To increase the efficiency of the storage capacity. • To avoid the seepage during conveyanceandtoimprovetheconveyance efficiency.• To withstand against the natural calamities like Earthquake, landslide & floods, etc. • Resurrection of Drainage svstemsofDams.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Dam Cleaning</Link></h4>
                    <div className="text">Cleaningdamtoregainthestructural looks by removingall thegreenfungusandgrown weeds.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Gallery Reaming</Link></h4>
                    <div className="text">Reaming is a mechanical process that creates precision holes, typically after drilling, by rotating a precision cutting tool into a hole sized slightly under the diameter of thereamingfool.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Tunnel Lining</Link></h4>
                    <div className="text">Tunnels in loose rock andsoft soils are liable to disintegrate and, therefore, a lining is provided to strengthen their sides and roofs so as to prevent them from collapsing. The objectives of a lining are as follows. (a) Strengthening the sides and roofs to withstand pressure and prevent the tunnel fromcollapsing.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
              {/* Service Block Four */}
              <div className="service-block-four style-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to=""><img src="/images/resource/service-10.jpg" alt="" /></Link>
                  </div>
                  <div className="lower-content">
                    <div className="icon ficon flaticon-buildings"></div>
                    <h4><Link to="">Berm Drain</Link></h4>
                    <div className="text">A compacted earth or gravel ridge, excavated channel or a combination of ridge and channel designed to direct runoff away from or around disturbed areas. Diversions built on a level contour are used in combination with temporary slope drains toprovideadequateconveyance.</div>
                    <Link to="" className="read-more" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Page Section */}
      </div>

      {/* Modal */}
      <div className="modal fade full-experience-modal" id="fullExperience" tabIndex="-1" role="dialog" aria-labelledby="fullExperienceLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <h5 className="experience-title"></h5>
              <p className="full-text-para"></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
