import React from 'react'
import { Link } from 'react-router-dom'

export default function ServicesPage() {
    return (
        <div className="page-wrapper">
            {/* Page Title */}
            <section className="page-title" style={{ backgroundImage: 'url(/images/background/7.jpg)' }}>
                <div className="auto-container">
                    <h1>Services</h1>
                    <ul className="page-breadcrumb">
                        <li><Link to="">home</Link></li>
                        <li>Services</li>
                    </ul>
                </div>
            </section>
            {/* End Page Title */}
            {/* Sidebar Page Container */}
            <div className="sidebar-page-container left-sidebar">
                <div className="auto-container">
                    <div className="row clearfix">
                        {/* Sidebar Side */}
                        <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                            <aside className="sidebar sticky-top">
                                {/* Categories Widget */}
                                <div className="sidebar-widget categories-widget">
                                    <div className="widget-content">
                                        <ul className="blog-cat nav nav-tabs" role="tablist">
                                            <li><Link className="active" id="wallSawing-tab" data-toggle="tab" to="#wallSawing">Wall Sawing</Link></li>
                                            <li><Link id="coreDrilling-tab" data-toggle="tab" to="#coreDrilling">Core Drilling</Link></li>
                                            <li><Link id="curbDrilling-tab" data-toggle="tab" to="#curbDrilling">Curb Drilling</Link></li>
                                            <li><Link id="slabSawing-tab" data-toggle="tab" to="#slabSawing">Slab Sawing</Link></li>
                                            <li><Link id="handSawing-tab" data-toggle="tab" to="#handSawing">Hand Sawing</Link></li>
                                            <li><Link id="wireSawing-tab" data-toggle="tab" to="#wireSawing">Wire Sawing</Link></li>
                                            <li><Link id="grindingSurface-tab" data-toggle="tab" to="#grindingSurface">Grinding Surface Prap</Link></li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Contact Widget */}
                                <div className="sidebar-widget contact-widget">
                                    <div className="widget-content">
                                        <div className="sidebar-title">
                                            <h3>Contact :</h3>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="icon flaticon-maps-and-flags"></span>
                                                Laxmi Nagar, Jay Prakash Narayan Park, <br /> Delhi, India
                                            </li>
                                            <li>
                                                <span className="icon flaticon-big-envelope"></span>
                                                Email : construction@ulmech.com
                                            </li>
                                            <li>
                                                <span className="icon flaticon-time"></span>
                                                Phone : 07889005345 <br /> Support : 07889005345
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>
                        {/* Content Side */}
                        <div className="content-side col-lg-8 col-md-12 col-sm-12">

                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="wallSawing" role="tabpanel" aria-labelledby="wallSawing-tab">
                                    <div className="service-detail">
                                        <div className="inner-box">
                                            <p className="head-para">Wall Sawing</p>
                                            <p className="head-sub-para">We offer dustless, vibration-free concrete wall cutting for doors, windows, and more.</p>
                                            <div className="upper-box">
                                                <div className="image">
                                                    <img src="/images/resource/service-11.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h2><span className="icon flaticon-buildings"></span> Wall sawing or vertical depth up to a 36" thick wall
                                                    Concrete cutting details</h2>
                                                <p>Wall sawing is the cleanest, safest, most effective materials cut up to a 36" thick wall way to create door and window openings in concrete. Despite the name, concrete wall cutting powered by up to a 36" thick wall is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and dust free y floors.</p>
                                                <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Depth :</span> Up to a 36" thick wall</li>
                                                            <li><span>Type of cut :</span> Straight, Adjecebt and flush cut</li>
                                                            <li><span>Material cut :</span> Up to a 36" thick wall</li>
                                                            <li><span>Best for :</span> Walls, ceiling, floors</li>
                                                        </ul>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Powerd by :</span> Up to a 36" thick wall</li>
                                                            <li><span>Dust free :</span> Yes</li>
                                                            <li><span>Cut flush to adjecent surface? :</span> Yes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Job Info */}
                                                <div className="job-size-div">
                                                    <h4>Jobs of any size</h4>
                                                    <p>From a single doorway to a massive construction project, ULMECH is available for your vertical concrete cutting project.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="coreDrilling" role="tabpanel" aria-labelledby="coreDrilling-tab">
                                    <div className="service-detail">
                                        <div className="inner-box">
                                            <p className="head-para">Wall Sawing</p>
                                            <p className="head-sub-para">We offer dustless, vibration-free concrete wall cutting for doors, windows, and more.</p>
                                            <div className="upper-box">
                                                <div className="image">
                                                    <img src="/images/resource/service-11.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h2><span className="icon flaticon-buildings"></span> Wall sawing or vertical depth up to a 36" thick wall
                                                    Concrete cutting details</h2>
                                                <p>Wall sawing is the cleanest, safest, most effective materials cut up to a 36" thick wall way to create door and window openings in concrete. Despite the name, concrete wall cutting powered by up to a 36" thick wall is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and dust free y floors.</p>
                                                <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Depth :</span> Up to a 36" thick wall</li>
                                                            <li><span>Type of cut :</span> Straight, Adjecebt and flush cut</li>
                                                            <li><span>Material cut :</span> Up to a 36" thick wall</li>
                                                            <li><span>Best for :</span> Walls, ceiling, floors</li>
                                                        </ul>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Powerd by :</span> Up to a 36" thick wall</li>
                                                            <li><span>Dust free :</span> Yes</li>
                                                            <li><span>Cut flush to adjecent surface? :</span> Yes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Job Info */}
                                                <div className="job-size-div">
                                                    <h4>Jobs of any size</h4>
                                                    <p>From a single doorway to a massive construction project, ULMECH is available for your vertical concrete cutting project.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="curbDrilling" role="tabpanel" aria-labelledby="curbDrilling-tab">
                                    <div className="service-detail">
                                        <div className="inner-box">
                                            <p className="head-para">Wall Sawing</p>
                                            <p className="head-sub-para">We offer dustless, vibration-free concrete wall cutting for doors, windows, and more.</p>
                                            <div className="upper-box">
                                                <div className="image">
                                                    <img src="/images/resource/service-11.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h2><span className="icon flaticon-buildings"></span> Wall sawing or vertical depth up to a 36" thick wall
                                                    Concrete cutting details</h2>
                                                <p>Wall sawing is the cleanest, safest, most effective materials cut up to a 36" thick wall way to create door and window openings in concrete. Despite the name, concrete wall cutting powered by up to a 36" thick wall is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and dust free y floors.</p>
                                                <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Depth :</span> Up to a 36" thick wall</li>
                                                            <li><span>Type of cut :</span> Straight, Adjecebt and flush cut</li>
                                                            <li><span>Material cut :</span> Up to a 36" thick wall</li>
                                                            <li><span>Best for :</span> Walls, ceiling, floors</li>
                                                        </ul>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Powerd by :</span> Up to a 36" thick wall</li>
                                                            <li><span>Dust free :</span> Yes</li>
                                                            <li><span>Cut flush to adjecent surface? :</span> Yes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Job Info */}
                                                <div className="job-size-div">
                                                    <h4>Jobs of any size</h4>
                                                    <p>From a single doorway to a massive construction project, ULMECH is available for your vertical concrete cutting project.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="slabSawing" role="tabpanel" aria-labelledby="slabSawing-tab">
                                    <div className="service-detail">
                                        <div className="inner-box">
                                            <p className="head-para">Wall Sawing</p>
                                            <p className="head-sub-para">We offer dustless, vibration-free concrete wall cutting for doors, windows, and more.</p>
                                            <div className="upper-box">
                                                <div className="image">
                                                    <img src="/images/resource/service-11.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h2><span className="icon flaticon-buildings"></span> Wall sawing or vertical depth up to a 36" thick wall
                                                    Concrete cutting details</h2>
                                                <p>Wall sawing is the cleanest, safest, most effective materials cut up to a 36" thick wall way to create door and window openings in concrete. Despite the name, concrete wall cutting powered by up to a 36" thick wall is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and dust free y floors.</p>
                                                <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Depth :</span> Up to a 36" thick wall</li>
                                                            <li><span>Type of cut :</span> Straight, Adjecebt and flush cut</li>
                                                            <li><span>Material cut :</span> Up to a 36" thick wall</li>
                                                            <li><span>Best for :</span> Walls, ceiling, floors</li>
                                                        </ul>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Powerd by :</span> Up to a 36" thick wall</li>
                                                            <li><span>Dust free :</span> Yes</li>
                                                            <li><span>Cut flush to adjecent surface? :</span> Yes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Job Info */}
                                                <div className="job-size-div">
                                                    <h4>Jobs of any size</h4>
                                                    <p>From a single doorway to a massive construction project, ULMECH is available for your vertical concrete cutting project.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="handSawing" role="tabpanel" aria-labelledby="handSawing-tab">
                                    <div className="service-detail">
                                        <div className="inner-box">
                                            <p className="head-para">Wall Sawing</p>
                                            <p className="head-sub-para">We offer dustless, vibration-free concrete wall cutting for doors, windows, and more.</p>
                                            <div className="upper-box">
                                                <div className="image">
                                                    <img src="/images/resource/service-11.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h2><span className="icon flaticon-buildings"></span> Wall sawing or vertical depth up to a 36" thick wall
                                                    Concrete cutting details</h2>
                                                <p>Wall sawing is the cleanest, safest, most effective materials cut up to a 36" thick wall way to create door and window openings in concrete. Despite the name, concrete wall cutting powered by up to a 36" thick wall is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and dust free y floors.</p>
                                                <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Depth :</span> Up to a 36" thick wall</li>
                                                            <li><span>Type of cut :</span> Straight, Adjecebt and flush cut</li>
                                                            <li><span>Material cut :</span> Up to a 36" thick wall</li>
                                                            <li><span>Best for :</span> Walls, ceiling, floors</li>
                                                        </ul>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Powerd by :</span> Up to a 36" thick wall</li>
                                                            <li><span>Dust free :</span> Yes</li>
                                                            <li><span>Cut flush to adjecent surface? :</span> Yes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Job Info */}
                                                <div className="job-size-div">
                                                    <h4>Jobs of any size</h4>
                                                    <p>From a single doorway to a massive construction project, ULMECH is available for your vertical concrete cutting project.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="wireSawing" role="tabpanel" aria-labelledby="wireSawing-tab">
                                    <div className="service-detail">
                                        <div className="inner-box">
                                            <p className="head-para">Wall Sawing</p>
                                            <p className="head-sub-para">We offer dustless, vibration-free concrete wall cutting for doors, windows, and more.</p>
                                            <div className="upper-box">
                                                <div className="image">
                                                    <img src="/images/resource/service-11.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h2><span className="icon flaticon-buildings"></span> Wall sawing or vertical depth up to a 36" thick wall
                                                    Concrete cutting details</h2>
                                                <p>Wall sawing is the cleanest, safest, most effective materials cut up to a 36" thick wall way to create door and window openings in concrete. Despite the name, concrete wall cutting powered by up to a 36" thick wall is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and dust free y floors.</p>
                                                <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Depth :</span> Up to a 36" thick wall</li>
                                                            <li><span>Type of cut :</span> Straight, Adjecebt and flush cut</li>
                                                            <li><span>Material cut :</span> Up to a 36" thick wall</li>
                                                            <li><span>Best for :</span> Walls, ceiling, floors</li>
                                                        </ul>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Powerd by :</span> Up to a 36" thick wall</li>
                                                            <li><span>Dust free :</span> Yes</li>
                                                            <li><span>Cut flush to adjecent surface? :</span> Yes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Job Info */}
                                                <div className="job-size-div">
                                                    <h4>Jobs of any size</h4>
                                                    <p>From a single doorway to a massive construction project, ULMECH is available for your vertical concrete cutting project.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="grindingSurface" role="tabpanel" aria-labelledby="grindingSurface-tab">
                                    <div className="service-detail">
                                        <div className="inner-box">
                                            <p className="head-para">Wall Sawing</p>
                                            <p className="head-sub-para">We offer dustless, vibration-free concrete wall cutting for doors, windows, and more.</p>
                                            <div className="upper-box">
                                                <div className="image">
                                                    <img src="/images/resource/service-11.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h2><span className="icon flaticon-buildings"></span> Wall sawing or vertical depth up to a 36" thick wall
                                                    Concrete cutting details</h2>
                                                <p>Wall sawing is the cleanest, safest, most effective materials cut up to a 36" thick wall way to create door and window openings in concrete. Despite the name, concrete wall cutting powered by up to a 36" thick wall is not limited to walls—we use the same tools and techniques to make flush cuts into ceilings and dust free y floors.</p>
                                                <p>The old ways of pneumatic concrete breaking are obsolete. With 36 inches of depth, no overcuts and flush results, basement window cutting will not cause any structural damage.</p>
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Depth :</span> Up to a 36" thick wall</li>
                                                            <li><span>Type of cut :</span> Straight, Adjecebt and flush cut</li>
                                                            <li><span>Material cut :</span> Up to a 36" thick wall</li>
                                                            <li><span>Best for :</span> Walls, ceiling, floors</li>
                                                        </ul>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-12 col-sm-12">
                                                        <ul className="list-style-three">
                                                            <li><span>Powerd by :</span> Up to a 36" thick wall</li>
                                                            <li><span>Dust free :</span> Yes</li>
                                                            <li><span>Cut flush to adjecent surface? :</span> Yes</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Job Info */}
                                                <div className="job-size-div">
                                                    <h4>Jobs of any size</h4>
                                                    <p>From a single doorway to a massive construction project, ULMECH is available for your vertical concrete cutting project.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
