import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { isValidEmail, isValidPhone } from '../services/helper';
import { loader, loaderStop } from '../reducer/app-reducer';
import { handleException, post } from '../network/requests';
import { getUrl } from '../network/url';
import { notify } from '../services/react-toastify';

export default function ContactUsPage() {
    const dispatch = useDispatch();
    const [emailError, setEmailError] = useState([]);
    const [phoneError, setPhoneError] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();


    // send contact request 
    const submitHandler = (data) => {
        dispatch(loader(true));
        post(getUrl('contact-us'), data).then(response => {
            dispatch(loader(false));
            setShowSuccessMessage(response.data.message)
            reset();
        }).catch(error => {
            dispatch(loaderStop());
            if (!handleException(error) && error.response.data) {
                // get all server errors
                let formErrors = error.response.data.errors;
                if (formErrors) {
                    // set email validation error
                    if (formErrors.email) {
                        setEmailError(formErrors.email);
                    }
                    if (formErrors.email) {
                        setPhoneError(formErrors.phne);
                    }
                }

                notify('error', error.response.data.message);
            }
        })
    }


    return (
        <div className="page-wrapper">
            {/* Page Title */}
            <section className="page-title" style={{ backgroundImage: 'url(/images/background/12.jpg)' }}>
                <div className="auto-container">
                    <h1>Contact Us</h1>
                    <ul className="page-breadcrumb bg-transparent border-0 position-relative d-none">
                        <li><Link to="/" className="text-white">home</Link></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </section>
            {/* End Page Title */}
            {/* Contact Page Section */}
            <section className="contact-page-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        {/* Title Column */}
                        <div className="title-column col-lg-4 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="sec-title style-two">
                                    <div className="separater"></div>
                                    <div className="title">Contact Us</div>
                                    <h2>Get in Touch</h2>
                                    <div className="text">Have questions about our industry marketing services? let’s talk about how we can help you achieve your goals and take your business to the top! </div>
                                </div>
                            </div>
                        </div>
                        {/* Form Column */}
                        <div className="form-column col-lg-8 col-md-12 col-sm-12">
                            {showSuccessMessage ? <div class="alert alert-success" role="alert">
                                {showSuccessMessage}
                            </div> : null}
                            <div className="inner-column">
                                {/* Contact Form */}
                                <div className="contact-form">
                                    {/*Contact Form*/}
                                    <form method="post" action="" onSubmit={handleSubmit(submitHandler)} id="contact-form">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="username" placeholder="Name" {...register("name", { required: true })} />
                                                {errors?.name?.type == "required" && <p className='text-danger'>This field is required.</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="email" name="email" placeholder="Email" {...register("email", { required: true, validate: isValidEmail })} onChangeCapture={() => setEmailError([])} />
                                                {emailError.length ? <p className='text-danger'>{emailError[0]}</p> : null}
                                                {errors?.email?.type == "validate" && <p className='text-danger'>Invalid email.</p>}
                                                {errors?.email?.type == "required" && <p className='text-danger'>This field is required.</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" placeholder="Phone" {...register("phone", { required: true, validate: isValidPhone })} onChangeCapture={() => setPhoneError([])} />
                                                {phoneError.length ? <p className='text-danger'>{phoneError[0]}</p> : null}
                                                {errors?.phone?.type == "validate" && <p className='text-danger'>Invalid phone.</p>}
                                                {errors?.phone?.type == "required" && <p className='text-danger'>This field is required.</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" maxLength={250} name="subject" placeholder="Subject" {...register("subject")} />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <textarea maxLength={10000} name="message" placeholder="Message" {...register("message")}></textarea>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <button className="theme-btn btn-style-seven" type="submit" name="submit-form">Contact Now <span className="plus-box flaticon-plus-symbol"></span></button>
                                            </div>
                                        </div>
                                    </form>
                                    {/*End Contact Form */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Contact Page Section */}

            {/* Start Map Area */}
            <div className="auto-container mb-3">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14448.246492982458!2d55.2480082!3d25.1336074!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6900738c772b%3A0x7beeb30b439502ec!2sULMECH%20UAE!5e0!3m2!1sen!2sin!4v1719320442015!5m2!1sen!2sin" className="border-0 w-100 mt-3" style={{ height: '400px', borderRadius: '10px' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            {/* End Map Area */}
            {/* Contact Info Section */}
            <section className="contact-info-section pt-0">
                <div className="auto-container">
                    <div className="contact-info-div">
                        <div className="row align-items-center clearfix">
                            {/* Title Column */}
                            <div className="title-column col-lg-4 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="sec-title style-two">
                                        <div className="separater"></div>
                                        <div className="title">Contact Info</div>
                                        <h2>Get in Touch</h2>
                                        <div className="text">You will find yourself working in a true partnership that results in an incredible experience, and an end product that is the best.</div>
                                    </div>
                                </div>
                            </div>
                            {/* Info Column */}
                            <div className="info-column col-lg-8 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="row align-items-center clearfix">
                                        {/* Contact Column */}
                                        <div className="contact-column col-lg-6 col-md-6 col-sm-12">
                                            <div className="inner">
                                                <div className="icon flaticon-call-3"></div>
                                                <ul>
                                                    <li><strong>Call Us On</strong></li>
                                                    <li>Phone : <Link to="tel:07889005345">07889005345</Link></li>
                                                    <li>Support : <Link to="tel:07889005345">07889005345</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Contact Column */}
                                        <div className="contact-column col-lg-6 col-md-6 col-sm-12">
                                            <div className="inner">
                                                <div className="icon flaticon-email-3"></div>
                                                <ul>
                                                    <li><strong>Email Us</strong></li>
                                                    <li><Link to="mailto:construction@ulmech.com">construction@ulmech.com</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Contact Column */}
                                        {/* <div className="contact-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div className="icon flaticon-time"></div>
                                            <ul>
                                                <li><strong>Opening Hours</strong></li>
                                                <li>Monday - Saturday</li>
                                                <li>9:00 am to 5pm</li>
                                            </ul>
                                        </div>
                                    </div> */}
                                        {/* Contact Column */}
                                        {/* <div className="contact-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="inner">
                                            <div className="icon flaticon-placeholder"></div>
                                            <ul>
                                                <li><strong>Address</strong></li>
                                                <li>Al Quoz Industrial Area 2 - Dubai - United Arab Emirates</li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Contact Info Section */}

        </div>
    )
}
