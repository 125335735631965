import React from 'react'
import { Link } from 'react-router-dom'

export default function TilingSystemServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{backgroundImage: 'url(https://sika.scene7.com/is/image/sika/glo-tile-setting-grout-purple-tiles:16-9?wid=2440&hei=1373&fit=crop%2C1&fmt=webp-alpha)', backgroundRepeat: 'no-repea0t', backgroundPosition: 'bottom', backgroundSize: 'cover'}}>
        <div className="auto-container">
          <h1>TILING SYSTEM</h1>
          <ul className="page-breadcrumb d-none">
            <li><Link to="/">home</Link></li>
            <li><Link to="/refurbishment">Refurbishment</Link></li>
            <li>Tiling System</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li><Link to="/refurbishment">Refurbishment</Link></li>
                <li>Tiling System</li>
              </ul>
              <div className="service-detail">
                <div className="inner-box">
                  <p className="head-sub-para">No surface finishing covering allows as much possibility and creativity as a tiled surface – from graphic patterns and matte finish, to wood imitation and glass tiles. Many shapes, textures and colors in all sizes can be used in almost infinite combinations – the geometric puzzle comes together in any design you can dream of. The critical part comes when choosing how to install this beautiful tiled surface. </p>
                  <p className="head-sub-para">From decorative tiled surfaces to highly functional tiled wall and floor finishes, tiling is one of the most popular surface finishing techniques used to enhance the appearance and functionality of all types of buildings and facilities. It is always crucial to select the right tile and the right tile installation system for each different area of the specific project. This should include the correct surface preparation, waterproofing (if required), tile adhesive, tile grout and sealant.</p>
                  <p className="head-para text-center mt-5 mb-4">Tile Setting Systems Which Benefit Your Project</p>
                  <div className="lower-content">
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>We provide a guaranteed, comprehensive system approach and supply compatible products that can be used as integrated systems.</li>
                          <li>Our comprehensive range of products allows you to find the most suitable solution for new and repair projects, both indoor and outdoor, from one private home bathroom up to the largest scale, highrise project.</li>
                        </ul>
                      </div>
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>We have solutions that can be successfully applied in wet areas, like bathrooms, kitchens, balconies, and more critically watertight projects, such as swimming pools.</li>
                          <li>Our tile systems enable tile setters to achieve professional results quickly and cost-effectively.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p className="head-para text-center mt-5 mb-4">Materials for Many Types of Projects</p>
                  <p className="head-sub-para">It has a full range of cementitious grouts, epoxy grouts, polymethyl methacrylate (PMMA) grouts and polyurethane grouts to suit your project needs.</p>

                  {/* Application example */}
                  <p className="head-para text-center mt-5 mb-4">Application Examples</p>
                  <div className="row clearfix mt-4">
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-tile-setting-06?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Tiling with Large-Format Ceramic</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-tile-setting-07?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Tiling with Porcelain Stoneware</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-tile-setting-14?fit=crop%2C1&wid=480&hei=334&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Tiling on Drywall in Dry Areas</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-tile-setting-13?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Tiling on New or Renovated Balconies</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-tile-setting-10?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Tiling in and around Swimming Pools</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
