import React from 'react'
import { Link } from 'react-router-dom'

export default function InjectionServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(https://sika.scene7.com/is/image/sika/glo-injection-05:16-9?wid=2880&hei=1620&fit=crop%2C1&fmt=webp-alpha)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
        <div className="auto-container">
          <h1>INJECTION</h1>
          <ul className="page-breadcrumb d-none">
            <li><Link to="/">home</Link></li>
            <li><Link to="/waterproofing">Waterproofing</Link></li>
            <li>Injection</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li><Link to="/waterproofing">Waterproofing</Link></li>
                <li>Injection</li>
              </ul>
              <div className="service-detail">
                <div className="inner-box">
                  <p className="head-sub-para">Injection is a procedure of pumping cement-based, polyurethane-based, epoxy-based or acrylate-based material into damaged or cracked structures to securely seal leaks, repair compromised structures and make them watertight again for the long term.</p>
                  <p className="head-para text-center mt-5 mb-4">Injections Are a Good Choice Because They...</p>
                  <div className="lower-content">
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>Help avoid expensive costs of structural repairs and reduce operational downtime</li>
                          <li>Can be used for all types of leak-sealing applications in concrete, masonry and natural stone structures</li>
                          <li>Are able to form a new permanent watertight seal</li>
                        </ul>
                      </div>
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>Can be used at any time, including during initial construction or later to extend the service life during any subsequent repair or renovation, depending on the project’s specific requirements</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p className="head-para text-center mt-5 mb-4">Successful Waterproofing with Injection Systems</p>
                  <p className="head-sub-para">There are three primary success factors involved in ensuring the effectiveness and durability of injection projects. It is essential that the right combination of injection materials, injection equipment and injection method are selected. Together, this is what Sika’s extensive technical and practical experience provides:</p>
                  <p className="type-of-project-heading">Injection Material</p>
                  <p>The selection of the right injection material and the right specific injection product for the defined project requirements is the first key factor for success. The material’s viscosity, flexibility and behavior in contact with water are especially important - all which can significantly influence the effectiveness of the injection.</p>
                  <p className="type-of-project-heading">Injection Equipment</p>
                  <p>The second key success factor is having appropriate equipment for the selected injection material - for correct material preparation, mixing and delivery. This includes: the initial dosage and mixing, delivery from a suitable pump, to use of the right packers/ ports/ connectors.</p>
                  <p className="type-of-project-heading">Injection Method / Application</p>
                  <p>Thirdly, the correct injection method and application techniques must be followed by trained, skilled and experienced contractors, to ensure the success and provide complete, permanent leak-sealing solutions.</p>
                  <p className="head-para text-center mt-5 mb-4">Injection Solutions Provide Many Advantages</p>
                  <div className="lower-content mb-5">
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>We offer an extensive range of injection materials for all types of leak-sealing applications</li>
                          <li>Our injection solutions are tested and approved to the leading global standards</li>
                          <li>Sika’s experts provide technical advice and support</li>
                        </ul>
                      </div>
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>Selected Sika injection systems can also be used to increase or restore structural integrity and load-bearing capacity</li>
                          <li>Sika's high performance injection materials are fully compatible with Sika’s complete engineered waterproofing systems.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
