import React, { Fragment } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Loader from './components/common/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFoundPage from './pages/NotFoundPage';
import DemoPage from './pages/DemoPage';
import FrontendRoutes from './FrontendRoutes';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import WallSawingServicePage from './pages/services/WallSawingServicePage';
import CoreDrillingServicePage from './pages/services/CoreDrillingServicePage';
import SlabSawingServicePage from './pages/services/SlabSawingServicePage';
import HandSawingServicePage from './pages/services/HandSawingServicePage';
import WireSawingServicePage from './pages/services/WireSawingServicePage';
import GrindingServicePage from './pages/services/GrindingServicePage';
import RefurbishmentServicePage from './pages/services/RefurbishmentServicePage';
import GroutingServicePage from './pages/services/GroutingServicePage';
import TilingSystemServicePage from './pages/services/TilingSystemServicePage';
import WaterproofinglServicePage from './pages/services/WaterproofingServicePage';
import InjectionServicePage from './pages/services/InjectionServicePage';
import WaterproofingMortarServicePage from './pages/services/WaterproofingMortarServicePage';
import RoofingServicePage from './pages/services/RoofingServicePage';
import LiquidAppliedMembraneServicePage from './pages/services/LiquidAppliedMembraneServicePage';
import FlooringServicePage from './pages/services/FlooringServicePage';
import FloorJointsServicePage from './pages/services/FloorJointsServicePage';
import FloorJointsSealantServicePage from './pages/services/FloorJointsSealantServicePage';
import SawingServicePage from './pages/services/SawingServicePage';
import SealingBondingServicePage from './pages/services/SealingBondingServicePage';
import ExperienceAndExpertisePage from './pages/ExperienceAndExpertisePage';
import ServicesPage from './pages/services/ServicesPage';
import ConcreteFlooringServicePage from './pages/services/ConcreteFlooringServicePage';
function App() {
  return (
    <Fragment>
      <Router>
        <Routes>
          <Route element={<FrontendRoutes />}>
            <Route element={<HomePage />} path="/" />
            <Route element={<AboutUsPage />} path="/about-us" />
            <Route element={<ContactUsPage />} path="/contact-us" />
            <Route element={<ExperienceAndExpertisePage />} path="/experience-and-expertise" />
            <Route element={<ServicesPage />} path="/services" />
            <Route element={<WallSawingServicePage />} path="/wall-sawing" />
            <Route element={<SawingServicePage />} path="/sawing"/>
            <Route element={<CoreDrillingServicePage />} path="/core-drilling" />
            <Route element={<SlabSawingServicePage />} path="/slab-sawing" />
            <Route element={<HandSawingServicePage />} path="/hand-sawing" />
            <Route element={<WireSawingServicePage />} path="/wire-sawing" />
            <Route element={<GrindingServicePage />} path="/grinding" />
            <Route element={<RefurbishmentServicePage />} path="/refurbishment"/>
            <Route element={<GroutingServicePage />} path="/grouting" />
            <Route element={<TilingSystemServicePage />} path="/tiling-system" />
            <Route element={<WaterproofinglServicePage />} path="/waterproofing"/>
            <Route element={<InjectionServicePage />} path="/injection" />
            <Route element={<WaterproofingMortarServicePage />} path="/waterproofing-mortar" />
            <Route element={<RoofingServicePage />} path="/roofing"/>
            <Route element={<LiquidAppliedMembraneServicePage />} path="/liquid-applied-membrane" />
            <Route element={<FlooringServicePage />} path="/flooring"/>
            <Route element={<FloorJointsServicePage />} path="/floor-joints" />
            <Route element={<FloorJointsSealantServicePage />} path="/floor-joints-sealant" />
            <Route element={<ConcreteFlooringServicePage />} path="/concrete-flooring" />
            <Route element={<SealingBondingServicePage />} path="/sealing-bonding" />
           
            <Route element={<DemoPage />} path="/demo-page" />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Router>
      <Loader />
      <ToastContainer />
    </Fragment>
  );
}

export default App