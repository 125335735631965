import React from 'react'
import { Link } from 'react-router-dom'

export default function SealingBondingServicePage() {
  return (
		<div className="page-wrapper">
			{/* Page Title */}
			<section className="page-title" style={{ backgroundImage: 'url(/images/background/7.jpg)' }}>
				<div className="auto-container">
					<h1>SEALING & BONDING</h1>
					<ul className="page-breadcrumb d-none">
						<li><Link to="/">Home</Link></li>
						<li>Sealing & Bonding</li>
					</ul>
				</div>
			</section>
			{/* End Page Title */}
			{/* Sidebar Page Container */}
			<div className="auto-container">
				<ul className="page-breadcrumb">
					<li><Link to="/">Home</Link></li>
					<li>Sealing & Bonding</li>
				</ul>
				<div className="row my-5 clearfix justify-content-center">
					{/* Service Block */}

					<div className="service-block col-lg-4 col-md-6 col-sm-12">
						<Link to="floor-joints-sealant" className="w-100">
							<div className="inner-box">
								<div className="line-one"></div>
								<div className="line-two"></div>
								<div className="icon-box">
									<div className="icon flaticon-engineer"></div>
								</div>
								<h4>Floor Joints Sealant</h4>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
  )
}
