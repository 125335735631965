import React from 'react'
import { Link } from 'react-router-dom'

export default function GrindingServicePage() {
  return (
    <div className="page-wrapper">
    {/* Page Title */}
    <section className="page-title" style={{ backgroundImage: 'url(/images/grinding.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
      <div className="auto-container">
        <h1>GRINDING</h1>
        <ul className="page-breadcrumb d-none">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/sawing">Sawing</Link></li>
          <li>Grinding</li>
        </ul>
      </div>
    </section>
    {/* End Page Title */}
    {/* Sidebar Page Container */}
    <div className="sidebar-page-container left-sidebar">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-md-10 offset-md-1">
            <ul className="page-breadcrumb">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/sawing">Sawing</Link></li>
              <li>Grinding</li>
            </ul>
            <div className="service-detail">
              <div className="inner-box">
                <p className="head-sub-para">Grinding is a fundamental technique in construction and renovation projects, providing precise and efficient smoothing, shaping, and finishing of concrete, stone, and other solid surfaces. This method is ideal for preparing surfaces for coatings, removing imperfections, and achieving a polished finish.</p>
                <p className="head-sub-para">From surface preparation to detailed finishing, grinding is a versatile and essential service that enhances both the functionality and aesthetics of any building or facility. It is crucial to select the right grinding technique and equipment for the specific project, ensuring smooth and successful execution. This includes proper planning, execution, and safety measures to achieve the desired results without compromising the integrity of the structure.</p>
                <p className="head-para text-center mt-5 mb-4">Grinding Systems Which Benefit Your Project</p>
                <div className="lower-content">
                  <div className="row clearfix">
                    <div className="column col-lg-6 col-md-12 col-sm-12">
                      <ul className="list-style-three">
                        <li>We provide a guaranteed, comprehensive system approach and supply compatible products that can be used as integrated systems.</li>
                        <li>Our comprehensive range of grinding solutions allows you to find the most suitable method for new and repair projects, both indoor and outdoor, from small residential floors to large-scale commercial and industrial surfaces.</li>
                      </ul>
                    </div>
                    <div className="column col-lg-6 col-md-12 col-sm-12">
                      <ul className="list-style-three">
                        <li>We have solutions that can be successfully applied in various areas, including residential buildings, commercial properties, industrial facilities, and public spaces.</li>
                        <li>Our grinding systems enable contractors to achieve professional results quickly and cost-effectively.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="head-para text-center mt-5 mb-4">Equipment and Materials for Many Types of Projects</p>
                <p className="head-sub-para">We offer a full range of grinding equipment and materials, including:</p>
                <ul className="list-style-three">
                  <li><span>Diamond Grinding Wheels:</span> Ensuring precise and clean grinding for a variety of materials.</li>
                  <li><span>Handheld and Walk-Behind Grinding Machines:</span> Providing powerful and efficient grinding solutions for different project needs.</li>
                  <li><span>Safety Gear:</span> Essential for ensuring the safety of workers during the grinding process.</li>
                </ul>
                <p className="head-sub-para mb-5">Our products are designed to meet the highest standards of quality and performance, ensuring that your project is completed efficiently and to the highest level of satisfaction.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
