import React from 'react'
import { Link } from 'react-router-dom'

export default function CoreDrillingServicePage() {
  return (
    <div className="page-wrapper">
    {/* Page Title */}
    <section className="page-title" style={{ backgroundImage: 'url(/images/core-drilling.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
      <div className="auto-container">
        <h1>CORE DRILLING</h1>
        <ul className="page-breadcrumb d-none">
          <li><Link to="/">home</Link></li>
          <li><Link to="/sawing">Sawing</Link></li>
          <li>Core Drilling</li>
        </ul>
      </div>
    </section>
    {/* End Page Title */}
    {/* Sidebar Page Container */}
    <div className="sidebar-page-container left-sidebar">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-md-10 offset-md-1">
            <ul className="page-breadcrumb">
              <li><Link to="/">home</Link></li>
              <li><Link to="/sawing">Sawing</Link></li>
              <li>Core Drilling</li>
            </ul>
            <div className="service-detail">
              <div className="inner-box">
                <p className="head-sub-para">Core drilling is a vital technique in construction and renovation projects, enabling precise and efficient creation of circular holes in concrete, brick, and other solid materials. This method is essential for installing plumbing, electrical, and HVAC systems, as well as for extracting samples for analysis.</p>
                <p className="head-sub-para">From structural enhancements to utility installations, core drilling is a versatile and crucial service that supports both the functionality and aesthetics of any building or facility. It is important to select the right drilling technique and equipment for the specific project, ensuring smooth and successful execution. This includes proper planning, execution, and safety measures to achieve the desired results without compromising the integrity of the structure.</p>
                <p className="head-para text-center mt-5 mb-4">Core Drilling Systems Which Benefit Your Project</p>
                <div className="lower-content">
                  <div className="row clearfix">
                    <div className="column col-lg-6 col-md-12 col-sm-12">
                      <ul className="list-style-three">
                        <li>We provide a guaranteed, comprehensive system approach and supply compatible products that can be used as integrated systems.</li>
                        <li>Our comprehensive range of drilling solutions allows you to find the most suitable method for new and repair projects, both indoor and outdoor, from small residential installations to large-scale commercial constructions.</li>
                      </ul>
                    </div>
                    <div className="column col-lg-6 col-md-12 col-sm-12">
                      <ul className="list-style-three">
                        <li>We have solutions that can be successfully applied in various areas, including residential buildings, commercial properties, and industrial facilities.</li>
                        <li>Our core drilling systems enable contractors to achieve professional results quickly and cost-effectively.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="head-para text-center mt-5 mb-4">Equipment and Materials for Many Types of Projects</p>
                <p className="head-sub-para">We offer a full range of core drilling equipment and materials, including:</p>
                <ul className="list-style-three">
                  <li><span>Diamond Core Bits:</span> Ensuring precise and clean cuts for a variety of materials.</li>
                  <li><span>Hydraulic and Electric Core Drills:</span> Providing powerful and efficient drilling solutions for different project needs.</li>
                  <li><span>Safety Gear:</span> Essential for ensuring the safety of workers during the drilling process.</li>
                </ul>
                <p className="head-sub-para mb-5">Our products are designed to meet the highest standards of quality and performance, ensuring that your project is completed efficiently and to the highest level of satisfaction.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
