import React from 'react'
import { Link } from 'react-router-dom'

export default function ServicesPage() {
    return (
<section className="service-section pb-0">
    <div className="auto-container">
        {/* Sec Title  */}
        <div className="sec-title centered">

            <div className="separater"></div>

            <div className="title">What We Do For You</div>

            <h2>Services We Provide</h2>

        </div>
        <div className="row clearfix justify-content-center">
            {/* Service Block */}
            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="line-one"></div>
                    <div className="line-two"></div>
                    <div className="icon-box">
                        <img src="/images/icons/concrete-sawing.svg" className='position-relative z-1' width={60} height={60} alt="Concrete Sawing & Cutting" />
                    </div>
                    <h4 className='mt-4'>Concrete Sawing & Cutting</h4>
                    <div class="text mt-3">Concrete sawing and cutting involve using specialized equipment to precisely cut, shape, or remove concrete in construction, renovation, or demolition projects. Techniques include slab sawing, wall sawing, and core drilling. These methods ensure accurate cuts for openings, expansions.</div>
                    <a class="read-more mt-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>                
                </div>
            </div>

            {/* Service Block */}
            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="line-one"></div>
                    <div className="line-two"></div>
                    <div className="icon-box">
                    <img src="/images/icons/waterproofing-grouting.svg" className='position-relative z-1' width={60} height={60} alt="Waterproofing & Grouting" />
                    </div>
                    <h4 className='mt-3'>Waterproofing & Grouting</h4>     
                    <div class="text mt-3">Waterproofing and grouting are essential techniques in construction to prevent water ingress and ensure structural integrity. Waterproofing involves applying membranes or coatings to surfaces, protecting them from moisture. Grouting fills gaps and seals joints with a fluid material, providing stability and preventing water seepage.</div> 
                    <a class="read-more mt-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>            
                </div>
            </div>

            {/* Service Block */}
            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="line-one"></div>
                    <div className="line-two"></div>
                    <div className="icon-box">
                    <img src="/images/icons/geotechnical-construction.svg" className='position-relative z-1' width={60} height={60} alt="Geotechnical construction" />
                    </div>
                    <h4 className='mt-3'>Geotechnical construction</h4>    
                    <div class="text mt-3">Geotechnical construction involves engineering practices that address soil and rock behavior in construction projects. It includes foundation design, earth retention systems, ground improvement, and slope stabilization. By analyzing subsurface conditions, geotechnical engineers ensure structures are safely and efficiently built.</div>  
                    <a class="read-more mt-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>               
                </div>
            </div>

        </div>
        {/* <div className="lower-text text-center">
            <div className="text">Offering high quality construction solutions. <a href="#">Build Your Dream Now!</a></div>
        </div> */}
    </div>
</section>
    )
}
