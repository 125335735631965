import React from 'react'
import { Link } from 'react-router-dom'

export default function WaterproofingMortarServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(https://sika.scene7.com/is/image/sika/glo-tiles-swimming-pool-01:16-9?wid=2440&hei=1373&fit=crop%2C1&fmt=webp-alpha)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
        <div className="auto-container">
          <h1>WATERPROOFING MORTAR</h1>
          <ul className="page-breadcrumb d-none">
            <li><Link to="/">home</Link></li>
            <li><Link to="/waterproofing">Waterproofing</Link></li>
            <li>Waterproofing Mortar</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li><Link to="/waterproofing">Waterproofing</Link></li>
                <li>Waterproofing Mortar</li>
              </ul>
              <div className="service-detail">
                <div className="inner-box">
                  <p className="head-sub-para">Waterproofing mortars are used to protect structures against water infiltration. They are supplied as ready-to-use solutions to seal against damp soil, seepage and percolating water. Waterproofing mortars are often applied in water reservoirs, water retaining structures, basements and other engineering structures as well as beneath tiling for swimming pools, balconies, bathrooms and kitchens.</p>
                  <p className="head-para text-center mt-5 mb-4">Waterproofing Mortars Have Many Advantages</p>
                  <div className="lower-content">
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>Outstanding one-component or two-component product choice</li>
                          <li>Impermeability to water, verified with tests</li>
                          <li>Crack-bridging or rigid products available</li>
                          <li>Products for contact with drinking water</li>
                        </ul>
                      </div>
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>Additional concrete protection</li>
                          <li>Applicable on various substrates</li>
                          <li>Ready-to-use and easy to apply</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* Application example */}
                  <p className="head-para text-center mt-5 mb-4">Complete System Solutions for Specific Applications</p>
                  <div className="row clearfix mt-4">
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-waterproofing-mortar-basement-below-grade?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Below-Grade Structures and Basements</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-waterproofing-mortar-balcony-01?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Balconies and Terraces</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-waterproofing-mortar-pool-02?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Swimming Pools</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-waterproofing-mortar-bathroom-01?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Wet Rooms and Bathrooms</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-concrete-repair-03?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Bridges</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-waterproofing-mortar-concrete-structures-01?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                        </div>
                        <div className="lower-content">
                          <div className="icon flaticon-buildings"></div>
                          <h4><Link to="">Tanks and Reservoirs</Link></h4>

                          <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
