import React, { useEffect, useRef } from 'react'

export default function CounterAreaSection() {
  const professionalWorkersRef = useRef();
  const projectCompletedRef = useRef();
  const customerBenifitsRef = useRef();
  const awardsRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      for (let index = 0; index <= 943; index++) {
        setTimeout(() => {
            if(professionalWorkersRef.current){
                professionalWorkersRef.current.innerText = index;
            }
        }, 200 + (5*index));
      }

      for (let index = 0; index <= 4; index++) {
        setTimeout(() => {
            if(projectCompletedRef.current){
                projectCompletedRef.current.innerText = index;
            }
        }, 200 + (50*index));
      }

      for (let index = 0; index <= 19; index++) {
        setTimeout(() => {
            if(customerBenifitsRef.current){
                customerBenifitsRef.current.innerText = index;
            }
        }, 500 + (100*index));
      }

      for (let index = 0; index <= 6; index++) {
        setTimeout(() => {
            if(awardsRef.current){
                awardsRef.current.innerText = index;
            }
        }, 200 + (50*index));
      }
    }, 500);
  }, []);
  return (
    <section className="counter-section mb-5 mb-md-4">
    <div className="auto-container">
        {/* Fact Counter */}
        <div className="fact-counter-two">
            <div className="clearfix">
                {/* Column */}
                <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                    <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="lines"></div>
                            <div className="icon flaticon-engineer"></div>
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="3000" data-stop="943" ref={professionalWorkersRef}>0</span>+
                            </div>
                            <h4 className="counter-title">Professional Workers in Company</h4>
                        </div>
                    </div>
                </div>
                {/* Column */}
                <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                    <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="lines"></div>
                            <div className="icon flaticon-buildings"></div>
                            <div className="count-outer count-box alternate">
                                <span className="count-text" data-speed="5000" data-stop="4" ref={projectCompletedRef}>0</span>K
                            </div>
                            <h4 className="counter-title">Projects Competed Successfully</h4>
                        </div>
                    </div>
                </div>
                {/* Column */}
                <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                    <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="lines"></div>
                            <div className="icon flaticon-man"></div>
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="2000" data-stop="19" ref={customerBenifitsRef}>0</span>+
                            </div>
                            <h4 className="counter-title">Customers Benefit Every Day</h4>
                        </div>
                    </div>
                </div>
                {/* Column */}
                <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                    <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                        <div className="content">
                            <div className="lines"></div>
                            <div className="icon flaticon-trophy-1"></div>
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="3500" data-stop="06" ref={awardsRef}>0</span>
                            </div>
                            <h4 className="counter-title">Received Awards & Certifications</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
