export function setAccessToken(value) {
    const now = new Date();
    const ttl = Number(process.env.REACT_APP_ACCESS_TOKEN_EXPIRE_IN_HOURS) * 600000;
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem('access_token', JSON.stringify(item));
}

export function getAccessToken() {
    const itemStr = localStorage.getItem('access_token');
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem('access_token')
        return null
    }

    return item.value
}

export function removeAccessToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentUser');
    return null
}

export function authCheck() {
    return getAccessToken() ? true : false;
}

export const setCurrentUser = (data) => {
    return localStorage.setItem('currentUser', JSON.stringify(data));
}

export const getCurrentUser = () => {
    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
}

export const authHeader = () => {
    let token = getAccessToken();
    if (!token) {
        return 0;
    }
    return { headers: { 'Authorization': `Bearer ${token}` } };
}
