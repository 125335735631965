import React from 'react'
import { Link } from 'react-router-dom'

export default function ChoiceAreaSection() {
    return (
        <section className="choice-section">
            <div className="auto-container">
                <div className="row clearfix">
                    {/* Image Column */}
                    <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="pattern-layer" style={{ 'backgroundImage': 'url(/images/icons/pattern-4.png)' }}></div>
                            <div className="image">
                                <img loading="lazy" src="/images/resource/choice.webp" width={530} height={470} alt="Choice" />
                            </div>
                            <div className="image-two">
                                <img loading="lazy" src="/images/resource/choice-1.webp" width={360} height={295} alt="Choice" />
                            </div>                            
                        </div>
                    </div>
                    {/* Content Column */}
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title style-two">
                                <div className="separater"></div>
                                <div className="title">Why Our Choice</div>
                                <h2>Faster and More Efficient Solutions</h2>
                            </div>
                            <div className="text">
                                <p>Welcome to ULMECH, where we specialize in delivering Faster and More Efficient Solutions for all your construction needs. With a focus on innovation and cutting-edge technology, we ensure swift project completion without compromising quality. Our skilled team is committed to seamless collaboration, maximizing productivity on every job. At ULMECH, meeting deadlines and exceeding expectations is our priority. Experience the difference with ULMECH – where efficiency meets excellence. Contact us today to discover how we can accelerate your construction projects</p>
                            </div>
                            <div className="row clearfix">
                                <div className="column col-lg-6 col-md-6 col-sm-12">
                                    <ul className="list-style-two">
                                        <li>Rapid Project Delivery</li>
                                        <li>Innovative Equipment</li>
                                    </ul>
                                </div>
                                <div className="column col-lg-6 col-md-6 col-sm-12">
                                    <ul className="list-style-two">
                                        <li>Agile Team Collaboration</li>
                                        <li>Client-Centered Approach</li>
                                    </ul>
                                </div>
                            </div>
                            <Link to="/contact-us" className="read-more">Let's Talk! <span className="plus flaticon-plus-symbol"></span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
