import React from 'react'
import HeroAreaComponent from '../components/home/HeroAreaComponent'
import ServiceAreaSection from '../components/home/ServiceAreaSection'
import GalleryAreaSection from '../components/home/GalleryAreaSection'
import CounterAreaSection from '../components/home/CounterAreaSection'
import OurRcentProjectsAreaComponent from '../components/home/OurRcentProjectsAreaComponent'
import TestimonialAreaComponent from '../components/home/TestimonialAreaComponent'
import ChoiceAreaSection from '../components/home/ChoiceAreaSection'
import FaqSection from '../components/home/FaqSection'
import ClientsSection from '../components/home/ClientsSection'

export default function HomePage() {
    return (
        <div className="page-wrapper home-overflow-hidden">
            <HeroAreaComponent />
            <ServiceAreaSection />
            <GalleryAreaSection />
            <CounterAreaSection />
            <OurRcentProjectsAreaComponent />
            <TestimonialAreaComponent />
            <ChoiceAreaSection />
            <FaqSection />
            <ClientsSection />
        </div>
    )
}
