import React from 'react'
import { Link } from 'react-router-dom'

export default function FloorJointsServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(https://sika.scene7.com/is/image/sika/glo-floor-joint-warehouse-02:16-9?wid=2440&hei=1373&fit=crop%2C1&fmt=webp-alpha)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
        <div className="auto-container">
          <h1>FLOOR JOINTS</h1>
          <ul className="page-breadcrumb d-none">
            <li><Link to="/">home</Link></li>
            <li><Link to="/flooring">Flooring</Link></li>
            <li>Floor Joints</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li><Link to="/flooring">Flooring</Link></li>
                <li>Floor Joints</li>
              </ul>
              <div className="service-detail">
                <div className="inner-box">
                  <p className="head-sub-para">Floor joints are a major challenge in both new construction and renovations and have a direct impact on the floor durability and aesthetic appearance. These joints are most apparent between different floor components, at the floor-to-wall connection and around columns and building entrances. The joint sealing system must accommodate movements between building elements and also meet various requirements depending on the functions and locations of the joints and their environment exposure. </p>
                  <p className="head-para text-center mt-5 mb-4">Detailing and Joint Sealing Solutions for Different Flooring Applications</p>
                  <div className="lower-content">
                    <div className="row clearfix mb-4">
                      <div className="column col-md-4 col-sm-12">
                        <img src="https://sika.scene7.com/is/image/sika/glo-man-applying-joint-sealant?fit=crop%2C1&wid=600&fmt=webp-alpha&cropN=0.04921875%2C0.128125%2C0.7796875%2C0.7604166666666666" alt="" style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                      <div className="column col-md-8 col-sm-12">
                        <p className="type-of-project-heading">Elastic Joint Sealants for Floor, Wall and Ceiling Jointing</p>
                        <p>There is no way to prevent all horizontal floor joints, but they are a major cause of damages in flooring. Therefore, the proper planning and design of a floor joint must be done with specific precautions to prevent future damage.</p>
                        <p className="mb-0">The floor design should also consider the use of cleaning machines and maintenance equipment. Sika solutions for these joint sealants include the well-proven, reliable Sikaflex® Pro-3 polyurethane sealant, which is suitable for many types of floor joints including connecting joints between different materials. Emseal®, a Sika brand, is one of the global, leading companies for expansion joints and construction sealants.</p>
                      </div>
                    </div>
                    <div className="row clearfix mb-4">
                      <div className="column col-md-4 col-sm-12">
                        <img src="https://sika.scene7.com/is/image/sika/glo-floor-joint-parking-garage-01?fit=crop%2C1&wid=600&hei=450&fmt=webp-alpha" alt="" style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                      <div className="column col-md-8 col-sm-12">
                        <p className="type-of-project-heading">The Ultimate Joint Solution for Parking Garages and Industrial Environments</p>
                        <p>For joints in floor substrates which have to withstand a lot of different stressors, we developed innovative, pre-fabricated panel systems called Sika® FloorJoint. It is used for the installation and repair of joints in concrete slabs and concrete screeds in car park decks, garage floors, ramps, storage areas, production halls and hospitals.</p>
                        <p className="mb-0">Apart from high mechanical resistance, the main advantage of the panels is the significant noise and vibration reduction when trafficked on. In combination with Sikadur-Combiflex® products, a fully watertight system is possible. They are also very easy to install and highly durable.</p>
                      </div>
                    </div>
                    <div className="row clearfix mb-4">
                      <div className="column col-md-4 col-sm-12">
                        <img src="https://sika.scene7.com/is/image/sika/glo-floor-to-wall-connection-02?fit=crop%2C1&wid=600&fmt=webp-alpha&cropN=0.0453125%2C0.1354916067146283%2C0.8828125%2C0.7757793764988009" alt="" style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                      <div className="column col-md-8 col-sm-12">
                        <p className="type-of-project-heading">Floor to Wall, Pipe or Column Connection</p>
                        <p>The radius cove is a standard Sika detail which is free of joints and makes a practically maintenance-free, smooth curve transition. Such coving is especially required in areas where water frequently contacts the floor-to-wall connection and other horizontal to vertical connections. It can be applied with Sikafloor® coving mortar and over-coated with Sikafloor® top coats.</p>
                        <p className="mb-0">In areas where there is no use of water, less complex coving assemblies may be specified. For example, a skirting board may be adhered to the wall before or after the flooring system installation.</p>
                      </div>
                    </div>
                    <div className="row clearfix  mb-5">
                      <div className="column col-md-4 col-sm-12">
                        <img src="https://sika.scene7.com/is/image/sika/glo-drainage-channel?fit=crop%2C1&wid=600&fmt=webp-alpha&cropN=0.00234375%2C0.0%2C0.99765625%2C0.765625" alt="" style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                      <div className="column col-md-8 col-sm-12">
                        <p className="type-of-project-heading">Solutions for Drainage Channels</p>
                        <p>Drainage channels should always be designed to be outside of trafficked areas wherever possible. In case of liquids falling to the floor, it should be adequately shaped to discharge them as quickly as possible to the channels. When traffic over channels is unavoidable, considerable attention should be given to the channel and cover grating fixings, as these are the most susceptible areas for premature failure.</p>
                        <p className="mb-0">Sika, together with our partner, supplies ready-to-install drainage systems to meet various standards and requirements.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
