import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
const owlConfig = {
    lazyLoad:true,
    lazyLoadEager:1,
    autoplayHoverPause:true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    loop: true,
    margin: 8,
    dots: true,
    nav: true,
    smartSpeed: 500,
    autoplay: 6000,
    navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
    responsive: {
        0: {
            items: 1,
            stagePadding: 15,
        },
        600: {
            items: 1,
            stagePadding: 15,
        },
        800: {
            items: 1,
            stagePadding: 35,
            margin: 12,
            nav: false,
        },
        1024: {
            items: 1,
            stagePadding: 35,
            margin: 12,
        },
        1200: {
            items: 1,
            stagePadding: 35,
            margin: 12,
        }
    }
};

export default function HeroAreaComponent() {
  return (
    <section className="main-slider /*auto-container*/">
    <OwlCarousel className="main-slider-carousel owl-carousel owl-theme" {...owlConfig}>
        <div className="slide" style={{ 'backgroundImage': 'url(/images/main-slider/image-9.png)' }}>
            <div className="color-layer"></div>
            <div className="circle-icon" style={{ 'backgroundImage': 'url(/images/main-slider/icon-1.png)' }}></div>
            <div className="auto-container">
                {/* Content boxed */}
                <div className="content-boxed">
                    <div className="title">When it Comes to Service</div>
                    <h1>We Excel Beyond <br />the Competition</h1>
                    <div className="text">Your trusted partner for professional concrete cutting since 2000. <br /> We combine years of experience with exceptional customer service <br /> to get the job done right.</div>
                    <div className="link-box clearfix">
                        <Link to="/about-us" className="theme-btn btn-style-three"><span className="txt">More Explore</span></Link>
                        <Link to="/contact-us" className="theme-btn btn-style-four"><span className="txt">Call Us!</span></Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="slide  w-100" style={{ 'backgroundImage': 'url(/images/main-slider/image-6.png)' }}>
            <div className="color-layer"></div>
            <div className="circle-icon" style={{ 'backgroundImage': 'url(/images/main-slider/icon-1.png)' }}></div>
            <div className="auto-container">
                {/* Content boxed */}
                <div className="content-boxed">
                    <div className="title">When it Comes to Service</div>
                    <h1>We Excel Beyond <br />the Competition</h1>
                    <div className="text">Your trusted partner for professional concrete cutting since 2000. <br /> We combine years of experience with exceptional customer service <br /> to get the job done right.</div>
                    <div className="link-box clearfix">
                        <Link to="/about-us" className="theme-btn btn-style-three"><span className="txt">More Explore</span></Link>
                        <Link to="/contact-us" className="theme-btn btn-style-four"><span className="txt">Call Us!</span></Link>
                    </div>
                </div>
            </div> 
        </div>

        <div className="slide" style={{ 'backgroundImage': 'url(/images/main-slider/image-5.png)' }}>
            <div className="color-layer"></div>
            <div className="circle-icon" style={{ 'backgroundImage': 'url(/images/main-slider/icon-1.png)' }}></div>
            <div className="auto-container">
                {/* Content boxed */}
                <div className="content-boxed">
                    <div className="title">When it Comes to Service</div>
                    <h1>We Excel Beyond <br />the Competition</h1>
                    <div className="text">Your trusted partner for professional concrete cutting since 2000. <br /> We combine years of experience with exceptional customer service <br /> to get the job done right.</div>
                    <div className="link-box clearfix">
                        <Link to="/about-us" className="theme-btn btn-style-three"><span className="txt">More Explore</span></Link>
                        <Link to="/contact-us" className="theme-btn btn-style-four"><span className="txt">Call Us!</span></Link>
                    </div>
                </div>
            </div>
        </div>
    </OwlCarousel>
</section>
  )
}
