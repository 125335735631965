import React from 'react'
import { Link } from 'react-router-dom'

export default function RefurbishmentServicePage() {
  return (
    <div className="page-wrapper">
    {/* Page Title */}
    <section className="page-title" style={{ backgroundImage: 'url(/images/background/7.jpg)' }}>
      <div className="auto-container">
        <h1>REFURBISHMENT</h1>
        <ul className="page-breadcrumb d-none">
          <li><Link to="/">Home</Link></li>
          <li>Refurbishment</li>
        </ul>
      </div>
    </section>
    {/* End Page Title */}
    {/* Sidebar Page Container */}
    <div className="auto-container">
      <ul className="page-breadcrumb">
        <li><Link to="/">Home</Link></li>
        <li>Refurbishment</li>
      </ul>
      <div className="row my-5 clearfix justify-content-center">
        {/* Service Block */}

        <div className="service-block col-lg-4 col-md-6 col-sm-12">
          <Link to="/grouting" className="w-100">
            <div className="inner-box">
              <div className="line-one"></div>
              <div className="line-two"></div>
              <div className="icon-box">
                <div className="icon flaticon-engineer"></div>
              </div>
              <h4>Grouting</h4>
            </div>
          </Link>
        </div>

        {/* Service Block */}
        <div className="service-block col-lg-4 col-md-6 col-sm-12">
          <Link to="/tiling-system" className="w-100">
            <div className="inner-box">
              <div className="line-one"></div>
              <div className="line-two"></div>
              <div className="icon-box">
                <div className="icon flaticon-engineer"></div>
              </div>
              <h4>Tiling System</h4>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>

  )
}
