import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { isValidEmail } from '../../services/helper';
import { handleException, post } from '../../network/requests';
import { getUrl } from '../../network/url';
import { notify } from '../../services/react-toastify';
import { loader, loaderStop } from '../../reducer/app-reducer';

setTimeout(() => {
    var scrollTopBtn = document.querySelector('.scroll-to-top');
    if (scrollTopBtn) {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 300) {
                scrollTopBtn.classList.add('show');
            } else {
                scrollTopBtn.classList.remove('show');
            }
        });
    }
}, 1000)

export default function Footer() {
    const dispatch = useDispatch();
    const [emailError, setEmailError] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();




    // send contact request 
    const submitHandler = (data) => {
        dispatch(loader(true));
        post(getUrl('newslatter-subscription'), data).then(response => {
            dispatch(loader(false));
            setShowSuccessMessage(response.data.message)
            setTimeout(() => {
                setShowSuccessMessage("")
            }, 5000)
            reset();
        }).catch(error => {
            dispatch(loaderStop());
            if (!handleException(error) && error.response.data) {
                // get all server errors
                let formErrors = error.response.data.errors;
                if (formErrors) {
                    // set email validation error
                    if (formErrors.email) {
                        setEmailError(formErrors.email);
                    }
                }

                notify('error', error.response.data.message);
            }
        })
    }


    const scrollHandler = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <>
            <footer className="main-footer style-two" style={{ 'backgroundImage': 'url(/images/background/8.jpg)' }}>
                <div className="auto-container">
                    <div className="widgets-section">
                        <div className="row clearfix">
                            <div className="big-column col-lg-6 col-md-12 col-sm-12">
                                <div className="row clearfix">
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget contact-widget">
                                            <h4>Get In Touch</h4>
                                            <ul>
                                                <li>
                                                    <span className="icon flaticon-maps-and-flags"></span>
                                                    Al Quoz Industrial Area 2 - Dubai - United Arab Emirates
                                                </li>
                                                <li>
                                                    <span className="icon flaticon-big-envelope"></span>
                                                    Email : <Link to="mailto:construction@ulmech.com">construction@ulmech.com</Link>
                                                </li>
                                                <li>
                                                    <span className="icon flaticon-time"></span>
                                                    Phone : <Link to="tel:07889005345">07889005345</Link> <br /> Support : <Link to="tel:07889005345">07889005345</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <h4>Important Links</h4>
                                            <ul className="list-link">
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/about-us">About us</Link></li>
                                                <li><Link to="/contact-us">Contact Us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="big-column col-lg-6 col-md-12 col-sm-12">
                                <div className="row clearfix">
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget news-widget">
                                            <h4>Latest Projects</h4>
                                            <div className="widget-content">
                                                <div className="post">
                                                    <div className="thumb"><Link to=""><img loading="lazy" src="/images/resource/post-thumb-3.jpg" alt="" /></Link></div>
                                                    <h5><Link to="">Making slope bidge over highway.</Link></h5>
                                                    <span className="date">Start: 12 Dec 2023</span>
                                                </div>
                                                <div className="post">
                                                    <div className="thumb"><Link to=""><img loading="lazy" src="/images/resource/post-thumb-4.jpg" alt="" /></Link></div>
                                                    <h5><Link to="">Making slope bidge over highway.</Link></h5>
                                                    <span className="date">Start: 12 Dec 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget request-widget">
                                            <h4>Get Free Quote</h4>
                                            <div className="phone">07889005345</div>
                                            <div className="text">Start your dream project today</div>
                                            <Link to="/contact-us" className="request-btn theme-btn">Request With Online Form</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-section">
                        <div className="row clearfix">
                            <div className="newsletter-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="icon"><img loading="lazy" src="/images/icons/newsletter.png" alt="" /></div>
                                    <h3>Subscribe To Our Newsletter</h3>
                                    <div className="text">Stay in touch with us.</div>
                                </div>
                            </div>
                            <div className="form-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="newsletter-form">
                                        {showSuccessMessage ? <div class="alert alert-success text-center" role="alert">
                                            {showSuccessMessage}
                                        </div> : null}
                                        <form method="post" action="" onSubmit={handleSubmit(submitHandler)}>
                                            <div className="form-group">
                                                <input type="text" name="email" placeholder="Enter Your Email" {...register("email", { required: true, validate: isValidEmail })} onChangeCapture={() => setEmailError([])} />
                                                {emailError.length ? <p className='text-danger text-center'>{emailError[0]}</p> : null}
                                                {errors?.email?.type == "validate" && <p className='text-danger text-center'>Invalid email.</p>}
                                                {errors?.email?.type == "required" && <p className='text-danger text-center'>This field is required.</p>}
                                                <button type="submit" className="theme-btn flaticon-big-envelope"></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="column col-12">
                                <div className="copyright text-center">Copyright &copy; 2024 Construction. All rights reserved.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Scroll To Top */}
            <div className="scroll-to-top" onClick={scrollHandler}><span className="fa fa-angle-up"></span></div>

            {/* Start Nav Dropdown overlay */}
            <div className="nav-dropdown-overlay"></div>
            {/* End Nav Dropdown overlay */}
        </>
    )
}
