import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFoundPage() {
  return (
    <div className="page-wrapper">
      <section className="page-title" style={{ backgroundImage: 'url(/images/background/8.jpg)' }}>
        <div className="auto-container">
          <h1>Not Found</h1>
          <ul className="page-breadcrumb bg-transparent border-0 position-relative">
            <li><Link to="/" className="text-white">home</Link></li>
            <li>404</li>
          </ul>
        </div>
      </section>
      <section className="error-section">
        <div className="auto-container">
          <div className="content">
            <h1>404</h1>
            <h2>Oops! That page can’t be found</h2>
            <div className="text">Sorry, but the page you are looking for does not existing</div>
            <Link to="/" className="theme-btn btn-style-three"><span className="txt">Go to Home Page</span></Link>
          </div>
        </div>
      </section>

    </div>

  )
}
