import React from 'react'
import { Link } from 'react-router-dom'

export default function GroutingServicePage() {
  return (
    <div className="page-wrapper">
    {/*Page Title */}
    <section className="page-title" style={{backgroundImage: 'url(https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-06:16-9?wid=960&hei=540&fit=crop%2C1&fmt=webp-alpha)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
      <div className="auto-container">
        <h1>GROUTING</h1>
        <ul className="page-breadcrumb d-none">
          <li><Link to="/">home</Link></li>
          <li><Link to="/refurbishment">Refurbishment</Link></li>
          <li>Grouting</li>
        </ul>
      </div>
    </section>
    {/*End Page Title */}
    {/*Sidebar Page Container */}
    <div className="sidebar-page-container left-sidebar">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-md-10 offset-md-1">
            <ul className="page-breadcrumb">
              <li><Link to="/">home</Link></li>
              <li><Link to="/refurbishment">Refurbishment</Link></li>
              <li>Grouting</li>
            </ul>
            <div className="service-detail">
              <div className="inner-box">
                <p className="head-sub-para">Grout is a composite material generally consisting of water, cement, and sand. It is typically used for filling voids under machines or other structural elements, sealing joints and openings in surfaces and reinforcing existing structures.</p>
                <p className="head-para text-center mt-5 mb-4">Grout Solutions Have Many Advantages</p>
                <div className="lower-content">
                  <div className="row clearfix">
                    <div className="column col-lg-4 col-md-12 col-sm-12">
                      <div className="list-style-box">
                        <p className="para-1">High</p>
                        <p className="para-2">Quality</p>
                      </div>
                      <p className="list-style-heading">Easy handling</p>
                      <ul className="list-style-three">
                        <li>Pre-mixed to ensure high quality mortar</li>
                        <li>One-component product - just add water and mix</li>
                        <li>Shrinkage compensated</li>
                        <li>No segregation or bleeding</li>
                        <li>Can be poured or pumped</li>
                      </ul>
                    </div>
                    <div className="column col-lg-4 col-md-12 col-sm-12">
                      <div className="list-style-box">
                        <p className="para-1">High</p>
                        <p className="para-2">Precision</p>
                      </div>
                      <p className="list-style-heading">Free-flowing grout</p>
                      <ul className="list-style-three">
                        <li>Fills any voids, cavities, gaps and recesses (e.g. machine and base plates, dowling reinforcing bars, fixing posts, etc.)</li>
                        <li>Bedding joints in pre-cast concrete sections</li>
                      </ul>
                    </div>
                    <div className="column col-lg-4 col-md-12 col-sm-12">
                      <div className="list-style-box">
                        <p className="para-1">High</p>
                        <p className="para-2">Performance</p>
                      </div>
                      <p className="list-style-heading">Excellent bonding and strength</p>
                      <ul className="list-style-three">
                        <li>Excellent flow properties</li>
                        <li>Adjustable consistency</li>
                        <li>Fast strength developmen</li>
                        <li>Highly effective bearing area</li>
                        <li>Good adhesion to concrete</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="head-para text-center mt-5 mb-4">Materials for Many Types of Projects</p>
                <p className="head-sub-para">It has a full range of cementitious grouts, epoxy grouts, polymethyl methacrylate (PMMA) grouts and polyurethane grouts to suit your project needs.</p>
                {/*All project */}
                <p className="type-of-project-heading">Cementitious Grouting</p>
                <div className="row clearfix mt-4">
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-13?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" height="176" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">General grouting</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-wind-turbine-concrete-foundation?fit=crop%2C1&wid=480&hei=319&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Wind turbine grouting</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-14?fit=crop%2C1&wid=480&hei=359&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Road work and landscaping</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-micro-concrete-plant?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Micro concrete</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="type-of-project-heading">Resin Grouting</p>
                <div className="row clearfix mt-4">
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-epoxy-grouting-02?fit=crop%2C1&wid=480&fmt=webp-alpha&cropN=0.071875%2C0.0%2C0.928125%2C0.9989583333333333" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Specialized grouting</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-epoxy-grouting-rail-fixing?fit=crop%2C1&wid=480&fmt=webp-alpha&cropN=0.0%2C0.10645724258289703%2C1.0%2C0.8917975567190227" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Rail fixing</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-epoxy-grouting-airport?fit=crop%2C1&wid=480&fmt=webp-alpha&cropN=0.1734375%2C0.0%2C0.82578125%2C0.9988290398126464" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Fast curing grout</Link></h4>
                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Application example */}
                <p className="head-para text-center mt-5 mb-4">Application Examples</p>
                <div className="row clearfix mt-4">
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-01?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Under grouting base plates</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-02?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Filling bedding joints in precast concrete sections</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-04?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Under grouting of equipment and machine bases</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-05%20(2)?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Filling and sealing cavities, gaps or voids</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-12?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Paving joints</Link></h4>

                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-micro-concrete-plant?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Post fixing</Link></h4>
                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-rigid-bonding-03?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Post fixing</Link></h4>
                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-10?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Concrete repairs</Link></h4>
                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-block-four mb-0 style-two col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                        <Link to=""><img src="https://sika.scene7.com/is/image/sika/glo-cementitious-grouting-09?fit=crop%2C1&wid=480&hei=360&fmt=webp-alpha" alt="" /></Link>
                      </div>
                      <div className="lower-content">
                        <div className="icon flaticon-buildings"></div>
                        <h4><Link to="">Fixing street furniture and traffic signs</Link></h4>
                        <Link to="" className="read-more invisible" data-toggle="modal" data-target="#fullExperience">Read More <span className="plus flaticon-plus-symbol"></span></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
