import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';

const owlConfig = {
    loop: true,
    margin: 30,
    nav: true,
    smartSpeed: 500,
    autoplay: 5000,
    navText: ['<span className="fa fa-angle-left"></span>', '<span className="fa fa-angle-right"></span>'],
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        800: {
            items: 2
        },
        1024: {
            items: 2
        },
        1200: {
            items: 2
        }
    }
};

export default function TestimonialAreaComponent() {
    return (
        <section className="testimonial-section-two pb-0">
            <div className="auto-container">
                {/* Sec Title */}
                <div className="sec-title style-two centered">
                    <div className="separater"></div>
                    <div className="title">Testimonial For Us</div>
                    <h2>Impressive Words from Customers</h2>

                </div>
                <OwlCarousel className="two-item-carousel owl-carousel owl-theme" {...owlConfig}>
                    {/* Testimonial Block Two */}
                    <div className="testimonial-block-two">
                        <div className="inner-box">
                            <div className="quote-icon flaticon-quotation"></div>
                            <div className="upper-box">
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div>
                                <h4>Excellent Company</h4>
                                <div className="text">I am very Happy. Mike did a super job. I will be having for some more work later this summer on my sky lights. we recommend shelder.</div>
                            </div>
                            {/* Middle Box */}
                            <div className="middle-box">
                                <div className="middle-inner">
                                    <div className="content">                                       
                                        <h6>Edword Murphy</h6>
                                        <div className="designation">Manager Effiect</div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    {/* Testimonial Block Two */}
                    <div className="testimonial-block-two">
                        <div className="inner-box">
                            <div className="quote-icon flaticon-quotation"></div>
                            <div className="upper-box">
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div>
                                <h4>Wonderful Experience</h4>
                                <div className="text">Patrick & the staff were extremely easy to work & kept the project on schedule work was performed in a professional manner.</div>
                            </div>
                            {/* Middle Box */}
                            <div className="middle-box">
                                <div className="middle-inner">
                                    <div className="content">                                        
                                        <h6>MAX BenGamin</h6>
                                        <div className="designation">Social Worker</div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    {/* Testimonial Block Two */}
                    <div className="testimonial-block-two">
                        <div className="inner-box">
                            <div className="quote-icon flaticon-quotation"></div>
                            <div className="upper-box">
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div>
                                <h4>Excellent Company</h4>
                                <div className="text">I am very Happy. Mike did a super job. I will be having for some more work later this summer on my sky lights. we recommend shelder.</div>
                            </div>
                            {/* Middle Box */}
                            <div className="middle-box">
                                <div className="middle-inner">
                                    <div className="content">                                       
                                        <h6>Edword Murphy</h6>
                                        <div className="designation">Manager Effiect</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* Testimonial Block Two */}
                    <div className="testimonial-block-two">
                        <div className="inner-box">
                            <div className="quote-icon flaticon-quotation"></div>
                            <div className="upper-box">
                                <div className="rating">
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                </div>
                                <h4>Wonderful Experience</h4>
                                <div className="text">Patrick & the staff were extremely easy to work & kept the project on schedule work was performed in a professional manner.</div>
                            </div>
                            {/* Middle Box */}
                            <div className="middle-box">
                                <div className="middle-inner">
                                    <div className="content">                                        
                                        <h6>MAX BenGamin</h6>
                                        <div className="designation">Social Worker</div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </section>
    )
}
