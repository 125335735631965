import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function ClientsSection() {
    const [activeTab, setActiveTab] = useState('recent'); // 'recent' or 'upcoming'

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <section className="clients-section gallery-section" id="project">
            <div className="auto-container">
                <div className="sec-title style-two centered">
                    <div className="separater"></div>
                    <div className="title">Clients</div>
                    <h2>Our Respected Clients</h2>
                </div>

                <div className="mixitup-gallery">
                    <div className="filters clearfix">
                        <ul className="filter-tabs filter-btns text-center clearfix">
                            <li className={`filter ${activeTab === 'recent' ? 'active' : ''}`} onClick={() => handleTabClick('recent')}>
                                <span className="icon flaticon-folder"></span> Recent Client
                            </li>
                            <li className={`filter ${activeTab === 'upcoming' ? 'active' : ''}`} onClick={() => handleTabClick('upcoming')}>
                                <span className="icon flaticon-folder"></span> Upcoming Client
                            </li>
                        </ul>
                    </div>
                    <div className="filter-list row clearfix">
                        {/* Render Recent Clients */}
                        {activeTab === 'recent' && (
                            <div className="recent-client">
                                <div className="inner-container">
                                    <div className="clearfix">
                                        {/* Render Recent Client Images */}
                                        {/* Replace with your actual image paths */}
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                                            <div key={`recent-client-${index}`} className="column col-lg-3 col-md-6 col-12">
                                                <div className="image">
                                                    <Link to=""><img loading="lazy" src={`/images/clients/india-client-${index}.webp`} alt="" /></Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Render Upcoming Clients */}
                        {activeTab === 'upcoming' && (
                            <div className="upcoming-client">
                                <div className="inner-container">
                                    <div className="clearfix">
                                        {/* Render Upcoming Client Images */}
                                        {/* Replace with your actual image paths */}
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                                            <div key={`upcoming-client-${index}`} className="column col-lg-3 col-md-6 col-12">
                                                <div className="image">
                                                    <Link to=""><img loading="lazy" src={`/images/clients/uae-client-${index}.webp`} alt="" /></Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
