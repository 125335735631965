import React from 'react'
import { Link } from 'react-router-dom'

export default function WireSawingServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(/images/wire-sawing.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
        <div className="auto-container">
          <h1>WIRE SAWING</h1>
          <ul className="page-breadcrumb d-none">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/sawing">Sawing</Link></li>
            <li>Wire Sawing</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/sawing">Sawing</Link></li>
                <li>Wire Sawing</li>
              </ul>
              <div className="service-detail">
                <div className="inner-box">
                  <p className="head-sub-para">Wire sawing is an advanced and precise technique in construction and renovation projects, enabling efficient cutting of thick concrete, steel, and other solid materials. This method is ideal for complex projects requiring large and deep cuts, such as bridge sections, large slabs, and heavy machinery foundations.</p>
                  <p className="head-sub-para">From structural modifications to detailed demolitions, wire sawing is a versatile and essential service that enhances both the functionality and integrity of any building or facility. It is crucial to select the right sawing technique and equipment for the specific project, ensuring smooth and successful execution. This includes proper planning, execution, and safety measures to achieve the desired results without compromising the integrity of the structure.</p>
                  <p className="head-para text-center mt-5 mb-4">Wire Sawing Systems Which Benefit Your Project</p>
                  <div className="lower-content">
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>We provide a guaranteed, comprehensive system approach and supply compatible products that can be used as integrated systems.</li>
                          <li>Our comprehensive range of sawing solutions allows you to find the most suitable method for new and repair projects, both indoor and outdoor, from small residential adjustments to large-scale commercial and industrial renovations.</li>
                        </ul>
                      </div>
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>We have solutions that can be successfully applied in various areas, including bridges, dams, large concrete structures, and industrial equipment.</li>
                          <li>Our wire sawing systems enable contractors to achieve professional results quickly and cost-effectively.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p className="head-para text-center mt-5 mb-4">Equipment and Materials for Many Types of Projects</p>
                  <p className="head-sub-para">We offer a full range of wire sawing equipment and materials, including:</p>
                  <ul className="list-style-three">
                    <li><span>Diamond Wire:</span> Ensuring precise and clean cuts for a variety of materials.</li>
                    <li><span>Wire Sawing Machines:</span> Providing powerful and efficient cutting solutions for different project needs.</li>
                    <li><span>Safety Gear:</span> Essential for ensuring the safety of workers during the sawing process..</li>
                  </ul>
                  <p className="head-sub-para mb-5">Our products are designed to meet the highest standards of quality and performance, ensuring that your project is completed efficiently and to the highest level of satisfaction.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
