import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom'
const owlConfig = {
    loop: true,
    margin: 30,
    nav: true,
    smartSpeed: 500,
    autoplay: 5000,
    navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        800: {
            items: 2
        },
        1024: {
            items: 4
        },
        1200: {
            items: 4
        }
    }
};
export default function OurRcentProjectsAreaComponent() {
    return (

        <section className="gallery-section-three pb-0">
            <div className="auto-container">
                {/* Sec Title */}
                <div className="sec-title style-two centered">
                    <div className="separater"></div>
                    <div className="title">Our Recent Projects</div>
                    <h2>Our Special Projects See Now</h2>
                </div>
            </div>
            <div className="outer-container">
                <OwlCarousel className="gallery-carousel-two owl-carousel owl-theme" {...owlConfig}>
                    {/* <div className="gallery-carousel-two owl-carousel owl-theme"> */}
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/13.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/14.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/15.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/16.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/13.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/14.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/15.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Gallery Block Three */}
                    <div className="gallery-block-three">
                        <div className="inner-box">
                            <div className="image">
                                <img loading="lazy" src="/images/gallery/16.jpg" alt="" />
                                <div className="tag">Commercial</div>
                                <div className="overlay-box">
                                    <div className="content">
                                        <div className="pattern-layer"></div>
                                        <div className="category">Commercial</div>
                                        <h4><Link to="">Mechanical Trim</Link></h4>
                                        <p>In automotive engineering, for instance, mechanical trim encompasses components like knobs, buttons, levers, and other control mechanisms used to adjust seats, mirrors, climate control, and audio systems. These components not only contribute to the convenience and comfort of the vehicle but also enhance the overall user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </OwlCarousel>

            </div>
        </section>
    )
}
