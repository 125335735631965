import React from 'react'
import { Link } from 'react-router-dom'

export default function SawingServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(/images/background/7.jpg)' }}>
        <div className="auto-container">
        </div>
      </section>
      {/* End Page Title */}
{/* Hero div area start */}
<div className='box-with-overlay request-widget'>
<h1>FULL SERVICE CONCRETE SAW CUTTING</h1>
<p>From a massive construction project to a single task in a basement, no concrete cutting job is too big or too small. We have the experience, knowledge, and equipment to, efficiently and cost-effectively, take care of your job.</p>
<div className='text-center'>
<a class="request-btn theme-btn" href="/contact-us">Contact Us</a>
</div>
</div>
{/* Hero div area start */}

      {/* Sidebar Page Container */}
      <div className="auto-container mt-4">
        <ul className="page-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li>Sawing</li>
        </ul>
        <div className="row clearfix justify-content-center margin-bottom-for-footer">
          {/* Service Block */}

          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="line-one"></div>
              <div class="line-two"></div>
              <div class="icon-box">
                <div class="icon flaticon-engineer"></div>
              </div>
              <h4>Wall Sawing</h4>
              <p class="read-more" href="services-single.html">Electric or Hydraulic</p>
              <div class="text">Create openings for doors, window and more in wall, floors and ceillings.</div>
              <a class="read-more my-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>
              <div class="row">
                <div class="col-md-6 service-type-paras">
                  <p>Depth</p>
                  <p>Up to 36"</p>
                </div>
                <div class="col-md-6 service-type-paras">
                  <p>Type of Cut</p>
                  <p>Straight</p>
                </div>
              </div>
            </div>
          </div>

          {/* Service Block */}
          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="line-one"></div>
              <div class="line-two"></div>
              <div class="icon-box">
                <div class="icon flaticon-engineer"></div>
              </div>
              <h4>Wall Sawing</h4>
              <p class="read-more" href="services-single.html">Electric or Hydraulic</p>
              <div class="text">Create openings for doors, window and more in wall, floors and ceillings.</div>
              <a class="read-more my-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>
              <div class="row">
                <div class="col-md-6 service-type-paras">
                  <p>Depth</p>
                  <p>Up to 36"</p>
                </div>
                <div class="col-md-6 service-type-paras">
                  <p>Type of Cut</p>
                  <p>Straight</p>
                </div>
              </div>
            </div>
          </div>

          {/* Service Block */}
          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="line-one"></div>
              <div class="line-two"></div>
              <div class="icon-box">
                <div class="icon flaticon-engineer"></div>
              </div>
              <h4>Wall Sawing</h4>
              <p class="read-more" href="services-single.html">Electric or Hydraulic</p>
              <div class="text">Create openings for doors, window and more in wall, floors and ceillings.</div>
              <a class="read-more my-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>
              <div class="row">
                <div class="col-md-6 service-type-paras">
                  <p>Depth</p>
                  <p>Up to 36"</p>
                </div>
                <div class="col-md-6 service-type-paras">
                  <p>Type of Cut</p>
                  <p>Straight</p>
                </div>
              </div>
            </div>
          </div>

          {/* Service Block */}
          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="line-one"></div>
              <div class="line-two"></div>
              <div class="icon-box">
                <div class="icon flaticon-engineer"></div>
              </div>
              <h4>Wall Sawing</h4>
              <p class="read-more" href="services-single.html">Electric or Hydraulic</p>
              <div class="text">Create openings for doors, window and more in wall, floors and ceillings.</div>
              <a class="read-more my-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>
              <div class="row">
                <div class="col-md-6 service-type-paras">
                  <p>Depth</p>
                  <p>Up to 36"</p>
                </div>
                <div class="col-md-6 service-type-paras">
                  <p>Type of Cut</p>
                  <p>Straight</p>
                </div>
              </div>
            </div>
          </div>

          {/* Service Block */}
          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="line-one"></div>
              <div class="line-two"></div>
              <div class="icon-box">
                <div class="icon flaticon-engineer"></div>
              </div>
              <h4>Wall Sawing</h4>
              <p class="read-more" href="services-single.html">Electric or Hydraulic</p>
              <div class="text">Create openings for doors, window and more in wall, floors and ceillings.</div>
              <a class="read-more my-4 d-block" href="">Read More <span class="arrow fa fa-angle-double-right"></span></a>
              <div class="row">
                <div class="col-md-6 service-type-paras">
                  <p>Depth</p>
                  <p>Up to 36"</p>
                </div>
                <div class="col-md-6 service-type-paras">
                  <p>Type of Cut</p>
                  <p>Straight</p>
                </div>
              </div>
            </div>
          </div>

          {/* Service Block */}

        </div>
      </div>
    </div>
  )
}
