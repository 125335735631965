import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
const hideDropdownOverlay = () => {
    document.querySelector('.nav-dropdown-overlay').classList.remove('active');
    document.querySelector('body').style.overflowY = "unset";
    document.querySelector('body').classList.remove('hover-active');
    document.querySelector('.main-header .nav-outer').style.boxShadow = "0px 0px 15px rgba(0, 0, 0, 0.1)";
}
setTimeout(() => {
    const nav = document.querySelector(".main-header");
    if (nav) {
        const navHeight = 65;
        let lastScrollY = 0;
        const delta = 10;

        function scrolled() {
            let sy = window.scrollY;
            if (Math.abs(lastScrollY - sy) > delta) {
                if (sy > lastScrollY && sy > navHeight) {
                    nav.classList.add("nav-up");
                } else if (sy < lastScrollY) {
                    nav.classList.remove("nav-up");
                }
                lastScrollY = sy
            }
        }

        // Add event listener & debounce so not constantly checking for scroll
        let didScroll = false;
        window.addEventListener("scroll", function (e) {
            didScroll = true;
        });

        setInterval(function () {
            if (didScroll) {
                scrolled();
                didScroll = false;
            }
        }, 250)
        // navbar scroll behaviour end
    }

    var navDropLink = document.querySelector('.dropdown.has-mega-menu');

    if (navDropLink) {
        if (document.querySelector('body')) {
            navDropLink.addEventListener('mouseover', function (e) {
                document.querySelector('body').classList.add('hover-active');
                document.querySelector('body').style.overflowY = "hidden";
                document.querySelector('.main-header .nav-outer').style.boxShadow = "none";
                document.querySelector('.nav-dropdown-overlay').classList.add('active')
            })
            navDropLink.addEventListener('mouseout', function () {
                hideDropdownOverlay();
            })
        }
    }
}, 1000)
export default function Navbar() {
    const [showNav, setShowNav] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const location = useLocation();
    const handleNavbar = () => {
        setShowNav(!showNav)
        if (document.querySelector('body')) {
            document.querySelector('body').classList.toggle("mobile-menu-visible");
        }
    }

    const handleServiceLink = (path) => {
        hideDropdownOverlay();
        document.querySelector("body").style.pointerEvents = "none";
        setTimeout(() => {
            document.querySelector("body").style.pointerEvents = "unset";
        }, 500)
        // navigate(path);
    }

    useEffect(() => {
        setShowNav(false)
        if (document.querySelector('body')) {
            document.querySelector('body').classList.remove("mobile-menu-visible");
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // hideDropdownOverlay()
    }, [location.pathname]);

    return (
        <Fragment>
            <header className="main-header header-style-one">
                <div className="nav-outer clearfix">
                    <div className="auto-container">
                        <div className={`mobile-nav-toggler ${showNav ? 'mobile-menu-visible' : ''}`} onClick={() => handleNavbar()}><span className="icon flaticon-menu-2"></span></div>
                        <nav className="main-menu show navbar-expand-md w-100">
                            <div className="navbar-header">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>

                            <div className="navbar-collapse collapse clearfix position-relative" id="navbarSupportedContent">
                                <div className="d-none d-md-block desktop-nav-icon"><Link to="/"><img loading="lazy" src="/images/logos/logo.webp" width="175" height="47" alt="" /></Link></div>
                                <ul className="navigation align-items-center d-md-flex mx-auto clearfix">

                                    <li className="dropdown has-mega-menu">
                                        <Link to={location.pathname}>Services</Link>
                                        <div className="mega-menu">
                                            <div class="auto-container">
                                                <div class="country-select">
                                                    <select>
                                                        <option className='d-none'>Select Country</option>
                                                        <option value="1">India</option>
                                                        <option value="2">UAE</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mega-menu-bar row clearfix auto-container">
                                                <div className="column col-lg-3 col-md-4 col-sm-12">
                                                    <h3><a href=''>Concrete Sawing and Cutting</a></h3>
                                                    <ul>
                                                        <li><Link to="/wall-sawing" onClick={() => handleServiceLink()}>Wall Sawing</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Wire Sawing</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Core Cutting</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Slab Sawing</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Surface Grinding</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Other Demolition</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>RCC Scanning</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="column col-lg-3 col-md-4 col-sm-12">
                                                    <h3><a href=''>Waterproofing & Grouting</a></h3>
                                                    <ul>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Sheet Waterproofing Membrane</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Joint Waterproofing</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Joint Waterproofing</Link></li>

                                                        <li><Link to="" onClick={() => handleServiceLink()}>Injections grouting</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Waterproofing Mortars</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Liquid-Applied Membrane</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Bituminous Membrane</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Roof System</Link></li>

                                                    </ul>
                                                </div>

                                                <div className="column col-lg-3 col-md-4 col-sm-12">
                                                    <h3><a href=''>Geotechnical construction</a></h3>
                                                    <ul>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Shotcrete placement</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Soil & rock stabilization</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Drilling & Anchor bar installation</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Wire Mesh installation</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Pipe piles</Link></li>
                                                        <li><Link to="" onClick={() => handleServiceLink()}>Structural walls</Link></li>

                                                    </ul>
                                                </div>

                                                {/* <div className="column col-lg-3 col-md-4 col-sm-12">
                                                    <h3><a href=''>Roofing</a></h3>
                                                    <ul>
                                                        <li><Link to="/liquid-applied-membrane" onClick={() => handleServiceLink()}>Liquid Applied Membrane</Link></li>
                                                    </ul>
                                                </div>

                                                <div className="column col-lg-3 col-md-4 col-sm-12">
                                                    <h3><a href=''>Flooring</a></h3>
                                                    <ul>
                                                        <li><Link to="/floor-joints" onClick={() => handleServiceLink()}>Floor Joint</Link></li>
                                                        <li><Link to="/concrete-flooring" onClick={() => handleServiceLink()}>Concrete Flooring</Link></li>
                                                    </ul>
                                                </div>

                                                <div className="column col-lg-3 col-md-4 col-sm-12">
                                                    <h3><a href=''>Sealing & Bonding</a></h3>
                                                    <ul>
                                                        <li><Link to="/floor-joints-sealant" onClick={() => handleServiceLink()}>Floor Joint Sealant</Link></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </li>
                                    <li><Link to="/about-us" onClick={() => handleServiceLink()}>About us</Link></li>
                                    <li><Link to="/contact-us" onClick={() => handleServiceLink()}>Contact us</Link></li>
                                </ul>
                            </div>
                        </nav>

                        <div className="options-box">
                            <div className="nav-btn mob-nav-logo d-block d-md-none"><Link to="/"><img loading="lazy" src="/images/logos/logo.webp" className='img-fluid' width="175" height="47" alt="Ulmech" /></Link></div>
                        </div>
                    </div>
                </div>

                <div className="mobile-menu">
                    <div className="menu-backdrop"></div>
                    <div className="close-btn"><span className="icon flaticon-multiply" onClick={() => handleNavbar()}></span></div>
                    <nav className="menu-box">
                        <div className="nav-logo"><Link to="/"><img loading="lazy" src="/images/logos/logo.webp" className='img-fluid' width={150} height={67} alt="Logo" title="Logo" /></Link></div>
                        <div class="country-select my-2">
                            <select className='w-100'>
                                <option className='d-none'>Select Country</option>
                                <option value="1">India</option>
                                <option value="2">UAE</option>
                            </select>
                        </div>
                        <ul className="navigation clearfix">
                            <li className="current dropdown" onClick={() => setShowServices(!showServices)}><Link to={location.pathname}>Services</Link>
                                <ul className={showServices ? 'd-block' : ''}>
                                    <li><Link to="/sawing" className="font-weight-bold">Concrete Sawing and Cutting</Link></li>
                                    <li><Link to="/wall-sawing">Wall Sawing</Link></li>
                                    <li><Link to="">Wire Sawing</Link></li>
                                    <li><Link to="">Core Cutting</Link></li>
                                    <li><Link to="">Slab Sawing</Link></li>
                                    <li><Link to="">Surface Grinding</Link></li>
                                    <li><Link to="">Other Demolition</Link></li>
                                    <li><Link to="">RCC Scanning</Link></li>

                                    <li><Link to="" className="font-weight-bold">Waterproofing & Grouting</Link></li>
                                    <li><Link to="">Sheet Waterproofing Membrane</Link></li>
                                    <li><Link to="">Joint Waterproofing</Link></li>
                                    <li><Link to="">Injections grouting</Link></li>
                                    <li><Link to="">Waterproofing Mortars</Link></li>
                                    <li><Link to="">Liquid-Applied Membrane</Link></li>
                                    <li><Link to="">Bituminous Membrane</Link></li>
                                    <li><Link to="">Roof System</Link></li>

                                    <li><Link to="" className="font-weight-bold">Geotechnical construction</Link></li>
                                    <li><Link to="">Shotcrete placement</Link></li>
                                    <li><Link to="">Joint Waterproofing</Link></li>
                                    <li><Link to="">Injections grouting</Link></li>
                                    <li><Link to="">Waterproofing Mortars</Link></li>
                                    <li><Link to="">Liquid-Applied Membrane</Link></li>
                                    <li><Link to="">Bituminous Membrane</Link></li>
                                    <li><Link to="">Roof System</Link></li>


                                    {/* <li><Link to="/roofing" className="font-weight-bold">Roofing</Link></li>
                                    <li><Link to="/liquid-applied-membrane">Liquid Applied Membrane</Link></li>

                                    <li><Link to="/flooring" className="font-weight-bold">Flooring</Link></li>
                                    <li><Link to="/floor-joints">Floor Joint</Link></li>

                                    <li><Link to="/sealing-bonding" className="font-weight-bold">Sealing & Bonding</Link></li>
                                    <li><Link to="/floor-joints-sealant">Floor Joint Sealant</Link></li> */}
                                </ul>
                                <div className={`dropdown-btn ${showNav ? 'open' : ''}`}><span className={showServices ? 'fa fa-angle-up' : 'fa fa-angle-down'}></span></div>
                            </li>
                            <li><Link to="/about-us">About us</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                        </ul>
                        <ul className="list-style-one mt-3">
                            <li><span className="icon fa fa-location-arrow"></span>Al Quoz Industrial Area 2 - Dubai - United Arab Emirates</li>
                            <li><span className="icon fa fa-phone"></span>07889005345</li>
                            <li><span className="icon fa fa-envelope"></span>construction@ulmech.com</li>
                        </ul>
                    </nav>
                </div>
            </header>

            {/* Scroll To Top */}
            <div className="scroll-to-top"><span className="fa fa-angle-up"></span></div>

            {/* Start Nav Dropdown overlay */}
            <div className="nav-dropdown-overlay"></div>
            {/* End Nav Dropdown overlay */}
        </Fragment>
    )
}
