import React from 'react'
import { Link } from 'react-router-dom'

export default function DemoPage() {
  return (
    <div>
      Demo Page
      <Link to={'/'}>Home</Link>
    </div>
  )
}
