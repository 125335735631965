import React from 'react'
import { Link } from 'react-router-dom'

export default function SlabSawingServicePage() {
  return (
    <div className="page-wrapper">
      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: 'url(/images/slab-sawing.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
        <div className="auto-container">
          <h1>SLAB SAWING</h1>
          <ul className="page-breadcrumb d-none">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/sawing">Sawing</Link></li>
            <li>Slab Sawing</li>
          </ul>
        </div>
      </section>
      {/* End Page Title */}
      {/* Sidebar Page Container */}
      <div className="sidebar-page-container left-sidebar">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-10 offset-md-1">
              <ul className="page-breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/sawing">Sawing</Link></li>
                <li>Slab Sawing</li>
              </ul>
              <div className="service-detail">
                <div className="inner-box">
                  <p className="head-sub-para">Slab sawing is a crucial technique in construction and renovation projects, providing precise and efficient cutting of concrete slabs, asphalt, and other solid surfaces. This method is ideal for creating expansion joints, trenches for utilities, and for removing damaged pavement sections.</p>
                  <p className="head-sub-para">From structural modifications to utility installations, slab sawing is a versatile and essential service that enhances both the functionality and appearance of any building or facility. It is important to select the right sawing technique and equipment for the specific project, ensuring smooth and successful execution. This includes proper planning, execution, and safety measures to achieve the desired results without compromising the integrity of the structure.</p>
                  <p className="head-para text-center mt-5 mb-4">Slab Sawing Systems Which Benefit Your Project</p>
                  <div className="lower-content">
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li> We provide a guaranteed, comprehensive system approach and supply compatible products that can be used as integrated systems.</li>
                          <li>Our comprehensive range of sawing solutions allows you to find the most suitable method for new and repair projects, both indoor and outdoor, from small residential driveways to large-scale commercial and industrial projects.</li>
                        </ul>
                      </div>
                      <div className="column col-lg-6 col-md-12 col-sm-12">
                        <ul className="list-style-three">
                          <li>We have solutions that can be successfully applied in various areas, including roads, bridges, parking lots, and industrial floors.</li>
                          <li>Our slab sawing systems enable contractors to achieve professional results quickly and cost-effectively.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p className="head-para text-center mt-5 mb-4">Equipment and Materials for Many Types of Projects</p>
                  <p className="head-sub-para">We offer a full range of slab sawing equipment and materials, including:</p>
                  <ul className="list-style-three">
                    <li><span>Diamond Blades:</span> Ensuring precise and clean cuts for a variety of materials.</li>
                    <li><span>Walk-Behind and Ride-On Sawing Machines:</span> Providing powerful and efficient cutting solutions for different project needs.</li>
                    <li><span>Safety Gear:</span> Essential for ensuring the safety of workers during the sawing process.</li>
                  </ul>
                  <p className="head-sub-para mb-5">Our products are designed to meet the highest standards of quality and performance, ensuring that your project is completed efficiently and to the highest level of satisfaction.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}
